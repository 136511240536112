import React from "react";

class Shortcuts extends React.Component {

    constructor (props) {
        super(props);

        // binding
        this.handleShowSearchWindow = this.handleShowSearchWindow.bind(this);
        this.shortcutClick = this.shortcutClick.bind(this); 
        this.state = {
            showInProcess: false
        }
    }

  
    
    componentDidMount() {
        this.componentDidUpdate()
    }

    
    componentDidUpdate() {
       /* const shortcuts = document.getElementById("shortcuts-wrapper")
        //const size = shortcuts[4].width();
        const size = '1000px'
        shortcuts.scrollRight = '250px'
        shortcuts.scrollLeft = '500px';
        //alert(size)*/
    }

    handleShowSearchWindow(event, show) {

        event.stopPropagation()
        
        this.props.openSearchClicked('0',show);
        const event_name = 'ShortcutAdvancedSearch';
        
        // add GTM custom event
        if (window['google_tag_manager']) {
            console.log('GTM event: ' + event_name)

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: event_name,
                eventTimeout: 2000,
                pageUrl: window.location,
                pageTitle : window.title,
            });
        }
    }

    shortcutClick(event, event_name, url) { 
        event.preventDefault();
        event.stopPropagation()

        const adults = this.props.filters.adults;
        const children = this.props.filters.children;
        const teens = this.props.filters?.teens;
        const infants = this.props.filters?.infants;

        // add GTM custom event
        if (window['google_tag_manager']) {
            console.log('GTM event: ' + event_name)

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: event_name,
                eventTimeout: 2000,
                target_url: url,
                pageUrl: window.location,
                pageTitle : window.title,
                eventCallback: function() {
                    window.location = url;
                }
            });
        } 

        if (!(adults==2 && children==0 && teens==0 && infants ==0))
                url+=`?adults=${adults}&children=${children}&teens=${teens}&infants=${infants}`;

        console.log(`shortcutClick() -> ${event_name} ${url}`)        
        window.location.href=url
            

    }

    render() {
        if (!this.props.hidden)
            return(
                <>
                <div id="shortcuts" className={this.props?.active||this.props?.searchResults?'shortcuts-block mobile':'shortcuts-block mobile'}>
                    
                    <div className="wrapper" id="shortcuts-wrapper">
                        <div className={this.props?.active=='best' || this.props?.active=='home'?'shortcut selected':'shortcut'} >
                            <a href="/best-deals/" title="הדילים הכי זולים" onClick={e=> {this.shortcutClick(e, 'ShortcutBestDeals', '/best-deals/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/paris.jpg")'}} alt="הדילים הכי זולים" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    הדילים הכי זולים
                                </div>
                            </a>
                        </div>

                        <div className={this.props?.active=='last-minute'?'shortcut selected':'shortcut'}>
                            <a href="/last-minute/" title="דילים ברגע האחרון"  onClick={e=> {this.shortcutClick(e, 'ShortcutLastMinute', '/last-minute/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/venice.jpg")'}} alt="דילים ברגע האחרון" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים ברגע האחרון
                                </div>
                            </a>
                        </div>
                        {/* 
                        <div className={this.props?.active=='xmas'?'shortcut selected new':'shortcut new'}>
                            <a href="/xmas-deals/" title="דילים בכריסמס"  onClick={e=> {this.shortcutClick(e, 'ShortcutXmas', '/xmas-deals/')}}>
                                <img className='new' src="/images/icons/new2.png" alt="חדש"></img>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("/images/shortcuts/xmas.jpg")'}} alt="דילים בקריסמס" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים בתקופת הכריסמס
                                </div>
                            </a>
                        </div>
                        */}
                        
                        {/* 
                        <div className={this.props?.active=='waterpark'?'shortcut selected new':'shortcut new'}>
                            <a href="/waterparks/" title="דילים במלונות עם פארק מים"  onClick={e=> {this.shortcutClick(e, 'ShortcutWaterparks', '/waterparks/')}}>
                                <img className='new' src="/images/icons/new2.png" alt="חדש"></img>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("/images/waterpark.jpg")'}} alt="דילים במלונות עם מגלשות" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    מלונות עם פארק מים
                                </div>
                            </a>
                        </div>
                        */}
                        <div className={this.props?.active=='passover-vacation-deals' || this.props?.active=='passover' || this.props?.active=='passover-deals' ?'shortcut selected new':'shortcut new'}>
                            <a href="/passover-vacation-deals/" title="דילים בחופש פסח"  onClick={e=> {this.shortcutClick(e, 'ShortcutPassover', '/passover-vacation-deals/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/barcelona.jpg")'}} alt="דילים בחופש פסח" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים בחופש פסח
                                </div>
                            </a>
                        </div>

                        <div className={this.props?.active=='weekends'?'shortcut selected':'shortcut'} >
                            <a href="/weekends/" title="דילים בסופי שבוע" onClick={e=> {this.shortcutClick(e, 'ShortcutWeekendDeals', '/weekends/')}}>
                                <div className="image">
                                <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/rome.jpg")'}} alt="דילים בסופי שבוע" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים בסופי שבוע
                                </div>
                            </a>
                        </div>
                        
                        
                        <div className={this.props?.active=='luxury'?'shortcut selected':'shortcut'} >
                            <a href="/luxury/" title="חופשות יוקרה" onClick={e=> {this.shortcutClick(e, 'Shortcut5Stars', '/luxury/')}}>
                                <div className="image">
                                <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/prague.jpg")'}} alt="דילים במלונות יוקרה" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים במלונות יוקרה
                                </div>
                            </a>
                        </div>
                        
                        
                        
                        {/* 
                        <div className={this.props?.active=='sukkot-vacation'?'shortcut sukkot selected':'shortcut sukkot new'} >
                            <a href="/sukkot-vacation-deals/" title="דילים בחופש סוכות" onClick={e=> {this.shortcutClick('ShortcutSukkot', '/sukkot-vacation-deals/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/amsterdam.jpg")'}} alt="דילים בחופש סוכות" />
                                </div>
                                <div className="label">
                                    דילים בחופש סוכות
                                </div>
                            </a>
                        </div>
                        */}

                        <div className={this.props?.active=='one-week'?'shortcut selected':'shortcut'}>
                            <a href="/one-week/" title="דילים לשבוע"  onClick={e=> {this.shortcutClick(e, 'ShortcutOneWeek', '/one-week/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/budapest.jpg")'}} alt="דילים לשבוע ימים" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    לוקחים ת'זמן (6-7 לילות)
                                </div>
                            </a>
                        </div>


                        

                           
                        {/* 
                        <div className={this.props?.active=='summer-family'?'shortcut summer-family selected':'shortcut summer-family new'} >
                            <a href="/summer-family/" title="דילים  למשפחות" onClick={e=> {this.shortcutClick(e, 'ShortcutSummerFamily', '/summer-family/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/paris.jpg")'}} alt="דילים למשפחות" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים למשפחות בקיץ
                                </div>
                            </a>
                        </div>
                        


                        <div className={this.props?.active=='all-inclusive'?'shortcut all-inclusive selected':'shortcut all-inclusive new'} >
                            <a href="/all-inclusive-deals/" title="דילים הכל כלול" onClick={e=> {this.shortcutClick('ShortcutAllInclusive', '/all-inclusive-deals/')}}>
                                <div className="image">
                                <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/papxhos.jpg")'}} alt="דילים הכל כלול" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים במלונות הכל-כלול
                                </div>
                            </a>
                        </div>
                        

                        <div className={this.props?.active=='passover-vacation'?'shortcut passover selected':'shortcut passover new'} >
                            <a href="/passover-vacation-deals/" title="דילים בפסח למשפחות" onClick={e=> {this.shortcutClick(e, 'ShortcutPassover', '/passover-vacation-deals/')}}>
                                <div className="image">
                                <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/amsterdam.jpg")'}} alt="דילים בפסח למשפחות" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים בחופש פסח
                                </div>
                            </a>
                        </div>
                        

                        <div className={this.props?.active=='summer-vacation'?'shortcut summer-vacation selected':'shortcut summer-vacation'} >
                            <a href="/summer-vacation/" title="דילים בחופש הגדול" onClick={e=> {this.shortcutClick(e, 'ShortcutSummerVacation','/summer-vacation/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/nice.jpg")'}} alt="דילים בחופש הגדול" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים בחופש-הגדול 
                                </div>
                            </a>
                        </div>
                        */}

{/*
                        <div className={this.props?.active=='summer'?'shortcut summer selected':'shortcut summer'} >
                            <a href="/summer-vacation/" title="דילים בחופש הגדול" onClick={e=> {this.shortcutClick(e, 'ShortcutSummer','/summer-vacation/')}}>
                                <img className='new' src="/images/icons/new2.png"></img>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/nice.jpg")'}} alt="דילים בקיץ" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    דילים בקיץ
                                </div>
                            </a>
                        </div>
 */}                    


 {/*                    <div className={this.props?.active=='xmas'?'shortcut xmas selected':'shortcut '} >
                            <a href="/xmas-deals/" title="דילים בכריסמס " onClick={e=> {this.shortcutClick(e, 'ShortcutXmas','/xmas-deals/')}}>
                                <img className='new' src="/images/icons/new2.png"></img>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/nice.jpg")'}} alt="דילים בכריסמס" />
                                </div>
                                <div className="label">
                                    דילים בתקופת הכריסמס
                                </div>
                            </a>
                        </div>

                        

*/}

                        <div className={this.props?.active=='earlybirds'?'shortcut earlybirds selected':'shortcut earlybirds new'} >
                            <a href="/early-birds/" title="מקדימים וחוסכים" onClick={e=> {this.shortcutClick('ShortcutSukkot', '/early-birds/')}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundImage:'url("https://cdn.cookiecrumbz.com/images/destinations/kos.jpg")'}} alt="מקדימים וחוסכים" />
                                </div>
                                <div className="label">
                                מקדימים וחוסכים
                                </div>
                            </a>
                        </div>
                            
                        <div className={this.props?.active=='search'?'shortcut search selected':'shortcut search'} >
                            <span href="#deals" title="חיפוש מותאם אישית" onClick={e=>{this.handleShowSearchWindow(e, false)}}>
                                <div className="image">
                                    <div className="rectangle" style={{backgroundPositionX:'center',backgroundImage:'url("https://st2.depositphotos.com/3538103/9751/v/600/depositphotos_97511882-stock-illustration-search-flat-design-icon.jpg")'}} alt="חיפוש מתקדם" />
                                    <div className="selector"></div>
                                </div>
                                <div className="label">
                                    חיפוש מותאם אישית
                                </div>
                            </span>
                        </div>
                        
                    </div>
                </div>
            </>
            ) 
            else
                return (<></>);
    }
}

export default Shortcuts