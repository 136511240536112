import React, { useState, useEffect, useContext, useRef } from "react";
import configData from '../../config/config.json';
import { useHistory } from "react-router-dom";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import {, Row, Col} from "react-bootstrap"
//import {babel} from "babel-loader"
import Navbar from "../../components/Navbar";
import "./package.css";
// import { Redirect } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
//import LocalAirportIcon from "@material-ui/icons/LocalAirport";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
//import ShieldIcon from "@material-ui/icons/VerifiedUser";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import HotelIcon from '@material-ui/icons/Hotel';
import FreeBreakfast from '@material-ui/icons/FreeBreakfast';
import PlaceIcon from '@material-ui/icons/Place';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly';
import Carousel from "react-material-ui-carousel";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import  LocationOnIcon  from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import axios from "axios";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
//import RadioButtonCheckedIcon from "@material-ui/icons/checkbox";
import QuantityModal from "./modals/QuantityModal";
import FlexDetailsModal from "./modals/FlexDetailsModal";
import PassengersChangeWarningModal from "./modals/PassengersChangeModal";
import PackageNotFoundModal from "./modals/PackageNotFoundModal";
import QuantityNotFoundModal from "./modals/QuantityNotFoundModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import StarIcon from "@material-ui/icons/Star";
import { GlobalContext } from "../../contexts/GlobalState";
import PriceChangeUpModal from "./modals/PriceChangeUpModal";
import PriceChangeDownModal from "./modals/PriceChangeDownModal";
import ErrorModal from "./modals/ErrorModal";
import YesNoModal from "./modals/YesNoModal";
import ChangeFlightModal from "./modals/ChangeFlightModal";
import ChangePropertyModal from "./modals/ChangePropertyModal";
import Spinner from "../../features/Spinner/spinner";
import TermsModal from "../Reservation/TermsModal.jsx";
import GMap from '../../features/Map';
import { scroller } from 'react-scroll' 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import MainNavigationBar from "../../features/Layout/MainNavigationBar";

moment.locale('he', {
  months: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_'),
  monthsShort: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_')
}); 

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: '1rem',//theme.typography.pxToRem(15),
    fontWeight: 'normal', //theme.typography.fontWeightRegular,
    fontFamily: 'assistant'
  },
}));

const API_ENDPOINT = configData.API_ENDPOINT;

const StyledRating = withStyles({
  iconFilled: {
    color: "#1e5881 !important",
  },
  iconHover: {
    color: "#1e5881 !important",
  },
})(Rating);

//const Package = ({ match }) => {
const Package = (props) => {
  const history = useHistory();
  let QSPackageUID = props.match.params.id;
  //const room = props.match.params.room;
  const classes = useStyles();
  const {
    preserveData,
    changePassengerQuantity,
    changeSelectedRoom
  } = useContext(GlobalContext);
  const [openPriceChangeUp, setOpenPriceChangeUp] = React.useState(false);
  const [openPriceChangeDown, setOpenPriceChangeDown] = React.useState(false);
  const [openPackageNotFound, setOpenPackageNotFound] = React.useState(false);
  const [openQuantityNotFound, setOpenQuantityNotFound] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [flights, setFlights] = React.useState([])
  const [properties, setProperties] = React.useState([])
  const [packagePricePP, setPackagePricePP] = React.useState([])
  const [openYesNo, setOpenYesNo] = React.useState(false);
  const [openChangeFlightModal, setOpenChangeFlightModal] = React.useState(false);
  const [changeMealPlanOnly, setChangeMeanPlanOnly] = React.useState(false);
  const [openChangePropertyModal, setOpenChangePropertyModal] = React.useState(false);
  const [openPassengersChangeWarning, setOpenPassengersChangeWarning] = React.useState(false);
  const [counter, setCounter] = useState(1);
  const [imageLoading, setImageLoading] = useState(false);
  let [detailState, setDetailState] = useState({});
  let [rtCheckHotelState, setRTCheckHotelState] = useState({})
  const [expanded, setExpanded] = React.useState(false);
  let [accordonView, setAccordonView] = useState(false);
  let [openAccordon, setOpenAccordon] = useState(false);
  let [showMap, setShowMap] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [selectedRoomUID, setRoomUID] = useState({});
  const [detailRoomState, setDetailRoomState] = useState([]);
  const [passengersChangeWarningModal, setPassengersChangeWarningModal] = useState(false);
  const [passengersChangeMessage, setPassengersChangeMessage] = useState("");
  //const [priceChangeModal, setPriceChangeModal] = useState(false);
  const [priceChangeMessage, setPriceChangeMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  //let quote_uid = null;
  const [termLink, setTermLink] = useState("");
  const [termsModal, setTermsModal] = useState(false);
  
  const [submitBasicButtonLoading, setSubmitBasicButtonLoading] = useState(false);
  const [submitFlexButtonLoading, setSubmitFlexButtonLoading] = useState(false);
  const [submitTGButtonLoading, setSubmitTGButtonLoading] = useState(false);

  //const [flexDetails, setFlexDetails] = useState({});
  const [openFlexDetailsModal, setOpenFlexDetailsModal] = useState(false);
  const [flexExpirationHours, setFlexExpirationHours] = useState(false);
  const [flexType, setFlexType] = useState(false);
  const [departureDate, setDepartureDate] = useState();
  const [returnDate, setReturnDate] = useState();
  const [destinationID, setDestinationID] = useState();

//  const [flexDetailsData, setFlexDetailsData] = useState('');
  
  // Manage paxes
  //const [adults, setAdults] = useState(new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults")*1:0)
  //const [teens, setTeens] = useState(new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens")*1:0);
  //const [children, setChildren] = useState(new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children")*1:0);
  //const [infants, setInfants] = useState(new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants")*1:0);
  //const [paxCount, setPaxCount] = useState(adults + teens + children + infants)
  let resHotel;
    
  useEffect(async () => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'dealView',
        pageUrl: window.url,
        pageTitle: window.title
    });
    
    
    try {

      
      if (QSPackageUID) {
        const Package = await loadPackage(QSPackageUID, false);
        console.log(Package)
        /*let adults = Package.pax.adults;
        let children = Package.pax.children;
        let infants = Package.pax.infants;*/

        // Load query string parameters or set default values
        let adults =   new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults")*1:0;
        let teens =    new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens")*1:0;
        let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children")*1:0;
        let infants =  new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants")*1:0;

        // New => We've renamed the paramters to a, t, c, i, so we've added this in order to have backward compatibility
        if (!new URLSearchParams(props.location.search).get("adults") && new URLSearchParams(props.location.search).get("a")) {
            adults =   new URLSearchParams(props.location.search).get("a")*1;
            teens =    new URLSearchParams(props.location.search).get("t")?new URLSearchParams(props.location.search).get("t")*1:0;
            children = new URLSearchParams(props.location.search).get("c")?new URLSearchParams(props.location.search).get("c")*1:0;
            infants =  new URLSearchParams(props.location.search).get("i")?new URLSearchParams(props.location.search).get("i")*1:0;

        }
        
        // Combine teens + children
        //children = children + teens;
        let paxCount = adults + teens + children + infants;

        if (paxCount != Package?.passengers_count)
          paxCount = 0;

        // Total Pax count
        //let paxCount  = adults + children + infants;
        // If no pax querystring parameters provided, use the pax from the package read from db

        if (!paxCount || !(paxCount>0)) {
          adults = Package.pax.adults;
          children = Package.pax.children;
          infants = Package.pax.infants;
          paxCount  = adults + teens + children + infants;
        }

        console.log(`PaxCount: ${paxCount}`)

  
        //teens = Package.pax.teens;

        const destinationCityName = Package.destination.city.name;
        const days = (Package?.nights)?(Package?.nights+1+' ימים'):'';
        let departureDate = '';
        if (Package?.departure?.date) {
          departureDate = Package?.departure?.date.split('T')[0];
          const dt = departureDate.split('-');
          departureDate = dt[2]+'/'+dt[1]+'/'+dt[0];
        }

        let returnDate = '';
        if (Package?.return?.date) {
          returnDate = Package.return.date.split('T')[0]
          const dt = returnDate.split('-');
          returnDate =  dt[2]+'/'+dt[1]+'/'+dt[0];
        }

        const dates = departureDate + '-' + returnDate;
        //console.log(dates)
        //const pax = Package?.pax;
        //const paxCount = pax?.adults + pax?.children + pax?.infants;

        //const vacations = Package.vacations&&Package.vacations.length>0?Package.vacations[0]:'';
        //const weekend = Package?.weekend_il;
        
        const price = Package?.price;
        const pricePP = Math.ceil(price / paxCount);
        const stars = Package?.property?.stars;

        // 4 ימים באמסטרדם  החל מ199$ - טיסה ישירה+מלון 3★ | עפנו חופשות סודיות
        let title = 'חופשה ב' + destinationCityName + ' ' + days + ' ב-$' + pricePP + ' ' + ' ('+dates+') טיסות ישירות+מלון ' + stars  + '★';
        document.title = title + ' | עפנו - חופשות סודיות';

//        this.setDescription(`✈ לא נמאס לכם לזרוק כסף? רק בעפנו מזמינים דיל זול ל${this.city}${this.month?` בחודש ${this.month}`:``} הכולל טיסה ומלון בבמחיר זול באמת!`)

        /*if (this.city) 
        title+= this.city;
      if (this.month && this.month!='')
        title+= ' ב' + this.month;
      */
     
      //this.setDescription(`✈ לא נמאס לכם לזרוק כסף? רק בעפנו מזמינים דיל זול ל${this.city}${this.month?` בחודש ${this.month}`:``} הכולל טיסה ומלון בבמחיר זול באמת!`)

        
        // Perform RT flight & hotel check 
//        console.log(Package)
/*setOpenPriceChangeUp({
  show: true, 
  city: {
    id: 66,
    uid: 'istanbul'
  },
  price: '$100'
});

return*/


        
  
        intervalCheckFlightAndHotel(Package, adults, children+teens, infants);
  

        // Update room details in GUI
        /*
        if(selectedRoomContext){
          setDetailRoomState(selectedRoomContext);
          let sr = selectedRoomContext.find((item) => {
            setRoomUID(item?.uid);
            return item?.best_offer == true;  
          });
          setSelectedRoom(sr);
        }
        else{
          console.log('Updating room state')
          setDetailRoomState(roomDetails);
  
          const search = window.location.search;
          const params = new URLSearchParams(search);
          const room_uid = params.get('room');
          if (room_uid!=null && room_uid.length>2) {
            
              console.log('room found in url')
              let roomsArr = [];
              
              detailRoomState.map((item) => {
                    if (item.uid == room_uid) {
                      roomsArr.push({ ...item, best_offer: true,selected: true });
                      setSelectedRoom(item)
                    } else {
                      roomsArr.push({ ...item, best_offer: false, selected: false });
                    }
              });
              
              //Set the default shown room to be the one from the querystring
              changeSelectedRoom(roomsArr)
  
              let sr = roomDetails.find((item) => {
                setRoomUID(item?.uid);
                return item?.uid == room_uid;
                
              });
  
              // Update pricing to reflect the room from the querystring
              setSelectedRoom(sr);
  
              //setSelectedRoom(selectedRoom);
              //handleChangeRoom(selectedRoom, id)
          } else {
            
            let sr = roomDetails.find((item) => {
              setRoomUID(item?.uid);
              return item?.best_offer == true;
            });
            console.log('Setting selected room')
            setSelectedRoom(sr);
          }
        } 
        */ 
      } // if

    } catch(e) {
        console.log(e)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ModalPackageNotFound',
            'pageUrl': window.url,
            'pageTitle' : window.title
        });
        showPageNotFoundPopup(true);
    }
  }, []); // useEffect



  let stars = detailState?.property?.stars;
  let rating = (detailState?.property?.rating)?detailState?.property?.rating:4;
  let arrivalDate = new Date(detailState?.flight?.outbound?.arrival_date);

  //Get Go time and seconds
  let goTime = parseInt(detailState?.flight?.outbound?.duration) / 60 / 60;
  let goSec = goTime - Math.floor(goTime);
  goSec = Math.round(goSec * 60);
  goTime = `${Math.floor(goTime)} שעות ו -${goSec} דקות`;

  //Get Return Time and Seconds
  let returnTime = parseInt(detailState?.flight?.inbound?.duration) / 60 / 60;
  let returnSec = returnTime - Math.floor(returnTime);
  returnSec = Math.round(returnSec * 60);
  returnTime = `${Math.floor(returnTime)} שעות ו -${returnSec} דקות`;

  // console.log(detailState)
  // console.log(detailRoomState)

  // Load package details from D
  const loadPackage = async function(packageUid, forceRefresh, rooms=null, sameProperty=false, ignoreNotFound=false) {

    console.log(`loadPackage`)
    const packageDetails = await loadPackageDetails(packageUid, forceRefresh, rooms, sameProperty);

    if (!packageDetails || packageDetails.error) {
      console.log(packageDetails?.error)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ModalPackageNotFound',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });

      if (!ignoreNotFound)
        showPageNotFoundPopup(true);

      return false;
    }

    if (packageDetails.closed) {
        showPageNotFoundPopup(true);
        return false;
    }

    // Update GUI with deal details
    let adults =   new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults")*1:0;
    let teens =    new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens")*1:0;
    let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children")*1:0;
    let infants =  new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants")*1:0;

    // New => We've renamed the paramters to a, t, c, i, so we've added this in order to have backward compatibility
    if (!new URLSearchParams(props.location.search).get("adults") && new URLSearchParams(props.location.search).get("a")) {
        adults =   new URLSearchParams(props.location.search).get("a")*1;
        teens =    new URLSearchParams(props.location.search).get("t")?new URLSearchParams(props.location.search).get("t")*1:0;
        children = new URLSearchParams(props.location.search).get("c")?new URLSearchParams(props.location.search).get("c")*1:0;
        infants =  new URLSearchParams(props.location.search).get("i")?new URLSearchParams(props.location.search).get("i")*1:0;
    }

    // Total Pax count
    let paxCount  = adults + children + infants + teens;

    // Combine teens + children
    //children = children + teens;
    

    if (paxCount > 0) {
        packageDetails.pax.adults = adults;
        packageDetails.pax.children = children + teens;
        packageDetails.pax.infants = infants;
    } else {
        // Incase no pax provides in querystring, use the package's built in pax
        paxCount  = packageDetails?.pax.adults + packageDetails?.pax.children + packageDetails?.pax.infants;
    }

    setDetailState(packageDetails);
    //quote_uid = packageDetails?.quote;

    // Load room data + photos
    //const roomDetails = await loadRoom(packageDetails.uid, packageDetails?.property?.room?.uid);
    /*if (roomDetails.error) {
      console.log(roomDetails.error)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ModalPackageNotFound',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });
      showPageNotFoundPopup(true);
      return false;
    }
  
    // Update GUI with room details
    setDetailRoomState(roomDetails)
    */

    // If we've received a different package UID => update the URL (this may happen if we've asked to change the number of passengers or if we've made a RT check and found an expired token => so we've generated a new package instead)
    if (QSPackageUID!==packageDetails?.uid) {
      QSPackageUID = packageDetails?.uid;                
      history.push(`/deal/${packageDetails?.uid}/`);
    }
   
    // Show room details in GUI
    //setDetailRoomState(roomDetails);

    // Find the selected room  
    /*let sr = roomDetails.find((item) => {
          setRoomUID(item?.uid);
          return item?.best_offer === true;
    });

    // Show selected room details in package details page
    setSelectedRoom(sr);   */
    
    return packageDetails;

  }
   
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  // Load package data from DB or force refresh data from supplier => May return a new package UID (and create an empty quote and return the quoteID)
  const loadPackageDetails = async function(packageUid, forceRefresh, rooms, sameProperty=false) {

    console.log(`logPackageDetails`)
    let url = `${API_ENDPOINT}/search/packages/${packageUid}/quote`
    if (rooms!=null)  {
      url += `?rooms=${JSON.stringify(rooms)}`;
    } else {
      url += `?`
    } 

    let adults =   new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults")*1:0;
    let teens =    new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens")*1:0;
    let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children")*1:0;
    let infants =  new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants")*1:0;

    if (!new URLSearchParams(props.location.search).get("adults") && new URLSearchParams(props.location.search).get("a")) {
        adults =   new URLSearchParams(props.location.search).get("a")*1;
        teens =    new URLSearchParams(props.location.search).get("t")?new URLSearchParams(props.location.search).get("t")*1:0;
        children = new URLSearchParams(props.location.search).get("c")?new URLSearchParams(props.location.search).get("c")*1:0;
        infants =  new URLSearchParams(props.location.search).get("i")?new URLSearchParams(props.location.search).get("i")*1:0;
    }

    let paxCount = adults + teens + children + infants;

    // Combine teens + children
    children = children + teens;


    if (paxCount>0) 
      url+=`&adults=${adults}&children=${children}&infants=${infants}`

    
    // Add the requested amount of passengers -> Notice when we enable this line the quote thinks the passengers amount has changed so it genertes a new package
    //if (adults>0)
    //   url += `&rooms=[{"adults":[${Array(adults).fill(30)}],"children":[${Array(children).fill(11)}],"infants":[${Array(adults).fill(2)}]}]`;

    if (sameProperty) 
      url +=`&same_property=${sameProperty}`;
      
    if (forceRefresh)
      url+= '&refresh';
      
    let resPackage = await axios.get(
      url,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true  
      }
    );

    setDepartureDate(resPackage.data?.departure?.date.split('T')[0]);
    setReturnDate(resPackage.data?.return?.date.split('T')[0])
    setDestinationID(resPackage.data?.destination?.city?.id)

    return resPackage.data;
    
  }

  ///////////////////////////////////////////////////////////////////////////////

  const loadRoom = async function(packageUid, roomUid) {
    
    const  resRoom = await axios.get(
      `${API_ENDPOINT}/search/packages/${packageUid}/rooms/${roomUid}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
  
    return resRoom.data
  
  }

  /*
  The following function will perform real time check with flights supplier and only after a response was received it will check the properties supplier.
  After supplier's response it'll loop every 15 seconds and second a new query to the flights supplier.

  If at any point the supplier failed to respond within the timeout limit, it will retry sending the request again up to 3 times.
  */
  const intervalCheckFlightAndHotel = async function(Package, adults, children, infants){
    return
    console.log('Recheck flight inventory')
    //console.log(Package)
    //console.log(window.location.pathname)
    //console.log(props.match.path)
    /// if user leave the reservation form => halt real-time check
    /*if (window.location.pathname != props.match.path) {
      // window.location.pathname => will always return the path of the real existing page being views
      // propts.match.path => returns the path of the page on first load (in our case, the reservation form)
      return;
    }*/

    // Load query string parameters or set default values

    const packageUid = Package.uid;
    const quoteUid = Package?.quote;
    const roomUid = Package?.property?.room?.uid
    //const pax = Package.pax;
    //const pax_count = pax?.adults + pax?.children + pax?.infants;

    if (Package===null || Package.uid===null)
      return;

    
    let resFlight;
    let flightRequestRetries = 3;
    let hotelRequestRetries = 3;

    // Check Flight
    try {
      resFlight = await axios.get(
        `${API_ENDPOINT}/search/packages/${packageUid}/check/flight/${quoteUid}?room=${roomUid}&adults=${adults}&children=${children}&infants=${infants}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          timeout: 10000
        }
      ); 
    } catch(e) {
      // If connection timed out, let try again
      if (e.code===`ECONNABORTED`) {
        // retry 3 times max
        if (flightRequestRetries-->0) {
          intervalCheckFlightAndHotel(Package, adults, children, infants);
          return;
        }
      }
    }
    if (resFlight?.data?.status === "error") {

      console.log(resFlight?.data?.error?.description)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ModalPackageNotFound',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });
      showPageNotFoundPopup(true);
      //setErrorMessage(resFlight?.data?.error?.description);
      //setOpenError(true);
      return false;
    } else if (resFlight?.data?.status === "invalid") {
      // TODO -> In this case we need to generate a new package!!!
      const Package = await loadPackage(packageUid, true, null);
      if (Package)
        intervalCheckFlightAndHotel(Package, adults,children,infants)
      return false;
    } else if (resFlight?.data?.status === "loading") {
        window.setTimeout(function() {intervalCheckFlightAndHotel(Package, adults, children, infants)}, 4000);
    } else if (resFlight?.data?.status === "valid") {

      // check Hotel only once

      try {
        resHotel = await axios.get(
          `${API_ENDPOINT}/search/packages/${packageUid}/check/property/${quoteUid}?room=${roomUid}&adults=${adults}&children=${children}&infants=${infants}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            withCredentials: true,
            timeout: 15000
          }
        );
      }
      catch(e) {          
        // If connection timed out, let try again
        if (e.code===`ECONNABORTED`) {
          // retry 3 times max
          if (hotelRequestRetries-->0)  {
            intervalCheckFlightAndHotel(Package, adults, children, infants);
            return;
          }
        }
      }


      if (resHotel?.data?.status === "valid") {
        //console.log('HOTEL VALIDATED')
        //console.log(resHotel?.data)
        setRTCheckHotelState(resHotel?.data);
        
        // If both flight & hotel changed

        // If both flight & hotel changed OR hotel change but flight didnt
        if ((resFlight?.data?.package?.pricing.changed && resHotel?.data?.package?.pricing.changed) || 
            (!resFlight?.data?.package?.pricing.changed && resHotel?.data?.package?.pricing.changed)) {
              // Verify that if there is a significant price change
              if (
                  Math.ceil(resFlight?.data?.package?.price+resHotel?.data?.package?.pricing?.basic?.price) 
                  != 
                  Math.ceil(
                    resFlight?.data?.package?.pricing?.basic?.price+resFlight?.data?.flight?.price_change_amount + 
                    resHotel?.data?.package?.pricing?.basic?.price+resHotel?.data?.property?.price_change_amount
                  )
               ){
                  // Update price based on hotel check
                  Package.price = resHotel?.data?.package?.pricing?.basic?.price;
                  setDetailState(Package);
                  /*const newPrice = getCurrencySymbol(resHotel?.data?.package?.pricing?.basic?.currency) + 
                                    Math.ceil(resHotel?.data?.package?.pricing?.basic?.price_pp);*/

                  // Obsolete
                  ////setPriceChangeMessage(newPrice);

                  // if package price has changed by more than $50 pp -> show popup
                  if (resFlight?.data?.package?.pricing?.change_pp + resHotel?.data?.package?.pricing?.change_pp > 50 ) {
                    /*if (resFlight?.data?.package?.pricing?.change_pp + resHotel?.data?.package?.pricing?.change_pp > 50 )  {
                      //  if price is higher than $50 pp show deal is closed popup
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                          event: 'ModalPackageNotFound',
                          'pageUrl': window.url,
                          'pageTitle' : window.title
                      });
                      console.log(resFlight?.data?.error?.description)
                      showPageNotFoundPopup(true);
                      //clearInterval(intervalCheckFlight);
                    } else */{

                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                          event: 'ModalPriceUp',
                          pageUrl: window.url,
                          pageTitle: window.title
                      });
                      
                      /*setOpenPriceChangeUp({
                        show: true, 
                        city: {
                          id: resHotel?.data?.package?.city_id,
                          uid: resHotel?.data?.package?.city_uid
                        },
                        price: newPrice
                      });*/
                    }
                    
                  }
                  else
                    if (resFlight?.data?.package?.pricing?.change + resHotel?.data?.package?.pricing?.change < -2 ) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'ModalPriceDown',
                            pageUrl: window.url,
                            pageTitle: window.title
                        });
                        setOpenPriceChangeDown(true);
                    }
              }
        } else { 
          // If only flight price changed
          if (resFlight?.data?.package?.pricing.changed) {
            if (Math.ceil(resFlight?.data?.package?.pricing?.basic?.price+resFlight?.data?.flight?.price_change_amount)!=Math.ceil(resFlight?.data?.package?.pricing?.basic?.price)) {

              Package.price = resFlight?.data?.package?.pricing?.basic?.price;
              setDetailState(Package);
              const newPricePP = getCurrencySymbol(resHotel?.data?.package?.pricing?.basic?.currency) + 
                               Math.ceil(resFlight?.data?.package?.pricing?.basic?.price/(resHotel?.data?.package.pax.adults+resHotel?.data?.package.pax.children+resHotel?.data?.package.pax.infants));

              setPriceChangeMessage(newPricePP);  

              
              if (resFlight?.data?.package?.pricing.change>50) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'ModalPriceUp',
                    pageUrl: window.url,
                    pageTitle: window.title
                });

                /*
                setOpenPriceChangeUp({
                    show: true, 
                    city: {
                      id: resFlight?.data?.package?.city_id,
                      uid: resFlight?.data?.package?.city_uid
                    },
                    price: newPrice
                  });*/
              }
              else  {
                if (resFlight?.data?.package?.pricing.change<-2) {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                      event: 'ModalPriceDown',
                      pageUrl: window.url,
                      pageTitle: window.title
                  });
                  setOpenPriceChangeDown(true);
                }
              }
            }  
          }
        }
        
        // Get new prices from DB ===>> TODO, these two db calls can be saved because we already know the new price and we can update it directly in the display
        //if (resFlight?.data?.package?.pricing.changed || resHotel?.data?.package?.pricing.changed) {
         /* resPackage = await axios.get(
            `${API_ENDPOINT}/search/packages/${uid}/?adults=${pax?.adults}&infants=${pax?.infants}&children=${pax?.children}&seniors=${pax?.seniors}`,
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }
          );
          setDetailState(resPackage?.data);
          uid = resPackage?.date?.uid;

          resRoom = await axios.get(
            `${API_ENDPOINT}/search/packages/${resPackage?.data.uid}/rooms`,
            {
              headers: {
                'Content-Type': 'application/json'
              },
              withCredentials: true
            }
          );*/


          /*
          if(selectedRoomContext){
            setDetailRoomState(selectedRoomContext);
            let sr = selectedRoomContext.find((item) => {
              setRoomUID(item.uid);
              return item?.best_offer == true;
            });
            setSelectedRoom(sr);
            
          }
          else{
            //setDetailRoomState(roomDetails);
            let sr = detailRoomState.find((item) => {
              setRoomUID(item.uid);
              return item?.best_offer == true;
            });
            setSelectedRoom(sr);
            
          }
          */

          setDetailState(Package);
          //const roomDetails = await loadRoom(Package.uid, Package?.property?.room?.uid);
          //setDetailRoomState(roomDetails);
        //}
        /*setReservationState({ 
          status: "valid",
          package: resHotel?.data?.package,
          flight: resFlight?.data?.flight,
          property: resHotel?.data?.property,
          session: resFlight?.data?.session,
          job: resFlight?.data?.job,
        });*/
        //console.log(Package);
        //console.log(Package?.selectedRoom)

        // Reset the flight query reties
        flightRequestRetries = 3;

        // if success => new a new interval to check flight every 15 seconds
        const intervalCheckFlight = async function() {

          //console.log(reservationState)
          // if user leave the reservation form => halt real-time check
          if (window.location.pathname!=props.match.path)
            return;
          
          try {
            resFlight = await axios.get(
              `${API_ENDPOINT}/search/packages/${packageUid}/check/flight/${quoteUid}?room=${roomUid}&adults=${adults}&children=${children}&infants=${infants}`,
              {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true,
                timeout: 10000
              }
            );
          }
          catch(e) {
            // If connection timed out, let try again
            if (e.code===`ECONNABORTED`) {
              // retry 3 times max
              if (flightRequestRetries-->0) {
                intervalCheckFlight();
                return;
              }
            }
          }
          

          // if flight is invalid show error
          if (resFlight?.data?.status === "invalid" || resFlight?.data?.status === "error") {
            //clearInterval(intervalCheckFlight);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'ModalPackageNotFound',
                'pageUrl': window.url,
                'pageTitle' : window.title
            });
            console.log(resFlight?.data?.error?.description)
            showPageNotFoundPopup(true);
            //setErrorMessage(resFlight?.data?.error?.description);
            //setOpenError(true);

            return false;
          }

          if (resFlight?.data?.status === "valid") {
            
            // If price has changed => Notify user
            /*if (resFlight?.data?.package?.pricing.changed) {
              setPriceChangeMessage(`FPackage price changed: Old price: ${resFlight?.data?.package?.pricing?.basic?.price-resFlight?.data?.flight?.price_change_amount}, 
              new Price: ${resFlight?.data?.package?.pricing?.basic?.price} (${resFlight?.data?.flight?.price_change_percents}% change)`);
              setOpenPriceChange(true);
              // Update display price
            }*/

            if (resFlight?.data?.package?.pricing.changed) {
              if (Math.ceil(resFlight?.data?.package?.pricing?.basic?.price+resFlight?.data?.flight?.price_change_amount)!=Math.ceil(resFlight?.data?.package?.pricing?.basic?.price)) {

                Package.price = resFlight?.data?.package?.pricing?.basic?.price;
                  setDetailState(Package);
                  //const newPrice = (getCurrencySymbol(resHotel?.data?.package?.pricing?.basic?.currency) + Math.ceil(resFlight?.data?.package?.pricing?.basic?.price_pp ));
                  
                  // obsolete
                  //setPriceChangeMessage(newPrice);  
                  
                  if (resFlight?.data?.package?.pricing.change > 50) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'ModalPriceUp',
                        pageUrl: window.url,
                        pageTitle: window.title
                    });

                    /*
                    setOpenPriceChangeUp({
                        show: true, 
                        city: {
                          id: resFlight?.data?.package?.city_id,
                          uid: resFlight?.data?.package?.city_uid
                        },
                        price: newPrice
                      });*/
                  }
                  else {
                    if (resFlight?.data?.package?.pricing.change < -2) {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                          event: 'ModalPriceDown',
                          pageUrl: window.url,
                          pageTitle: window.title
                      });                            
                      setOpenPriceChangeDown(true);
                    }
                  }
              }  
            }

              // Update price in display
              /*setReservationState({
                status: "valid",
                package: resFlight?.data?.package,
                flight: resFlight?.data?.flight,
                property: resHotel?.data?.property,
                session: resFlight?.data?.session,
                job: resFlight?.data?.job,
              });*/

          }

          window.setTimeout(function() {intervalCheckFlight(Package.uid)}, 15000); // once the first valid flight response received, check flight every 15 seconds indefenitly
        } 
        
        window.setTimeout(function() {intervalCheckFlight(Package.uid)}, 10000);  // Wait 10 second before checking flight again after we've received the response from the property check (this happens only once)

      } 
      else if (resHotel?.data?.status === "invalid") {
        // TODO -> In this case we need to generate a new package!!!
        const Package = await loadPackage(packageUid, true, null, true);
        intervalCheckFlightAndHotel(Package, adults, children, infants)
        return false;
      } else {
        //if (!open && !openPassengersChangeWarning) {
          // Invalid hotel response
          console.log(resHotel?.data?.error?.description)
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              event: 'ModalPackageNotFound',
              'pageUrl': window.url,
              'pageTitle' : window.title
          });
          showPageNotFoundPopup(true);
          //setErrorMessage(resHotel?.data?.error?.description);
          //setOpenError(true);

          return false;
        //}
      }
    } else {
      //if ( !open && !openPassengersChangeWarning) {
        // unexpected response from server
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ModalPackageNotFound',
            'pageUrl': window.url,
            'pageTitle' : window.title
        });
        showPageNotFoundPopup(true);
        //setErrorMessage(`Unexpected error. Please try again`);
        //setOpenError(true);
        return false;
      //}
    }
  } 


  const GetMealPlanName = (code) => {
    switch(code) {
      case 'HM': return 'חצי פנסיון'; // Breakfast and half board
      case 'MB': return 'חצי פנסיון + משקאות'; //Half board with drinks
      case 'MP': return 'חצי פנסיון'; //Half board
      case 'PC': return 'פנסיון מלא'; //Full board
      case 'PV': return 'פנסיון מלא + משקאות'; //Full board with drinks
      case 'SA': return 'לינה בלבד'; //Accommodation only
      case 'SP': return 'לינה בלבד'; //According to programme
      case 'TI': return 'הכל כלול'; //All inclusive
      case 'TP': return 'הכל כלול פרימיום'; //All inclusive premium
      case 'SC': return 'לינה בלבד'; //Self Catering
      case 'HD': return 'ארוחת בוקר'; //Bed and breakfast
    }
  };

  const handleClosePassengersChangeWarning = () => {
    setOpenPassengersChangeWarning(false);
    setOpen(true);
  }

  const handleClickOpenFlexDetails = (e, type, expiration_hours) => {
    e.preventDefault()
    //setFlexDetailsData(values);
    setOpenFlexDetailsModal(true);
    setFlexExpirationHours(expiration_hours);
    setFlexType(type);
  }

  const onOpenLink = (e, link) => {
    e.preventDefault()
    //window.open(link)
    setTermLink(link);
    setTermsModal(true);  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'ShowTermsModal',
        'pageUrl': window.url,
        'pageTitle' : window.title,
        'termUrl': link
    });
  };


  const handleCloseTerms = () => {
    setTermsModal(false);
  };

  const handleCloseFlexDetails = () => {
    setOpenFlexDetailsModal(false);
  };

  const gotoChangePaxPage = ()  => {
    window.location = `/דילים/נוספים/?destinations=${destinationID}&from_date=${departureDate}&to_date=${returnDate}&dates_type=exact&adults=${adults}&teens=${teens}&children=${children}&infants=${infants}&search=pax`;
  }

  const handleClickOpen = () => {
    console.log('handleClickOpen');

    setPassengersChangeMessage(`עדכון הרכב הנוסעים עשוי לגרום לשינוי בטיסות והמלון (היעד ותאריכי הנסיעה ישארו ללא שינוי)`);
    setOpenPassengersChangeWarning(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'ModalChangePassengers',
        'pageUrl': window.url,
        'pageTitle' : window.title,
        'page': 'deal_details'
    });
  };
  
  const handleClose = () => {
    // if user inside an error modal, return to homepage
    /*if (openError) {
      window.location = '/';
      return;
    }*/
    setOpenError(false);
    setOpen(false);
  };


  const handleCloseChangeFlight = () => {
    setOpenChangeFlightModal(false);
  }

  const handleCloseChangeProperty = () => {
    setOpenChangePropertyModal(false);
  }

  const handleOk = () => {
    setOpen(false);
    setOpenPriceChangeUp({
      show: false
    });

    setOpenPriceChangeDown(false);
  }

  const handleOkGoToHomepage = () => {
   // window.location = '/';
   
   // When go back from popup (after package not found), force homepage to refresh
   let ref = document.referrer?document.referrer+'':'';
   let current = window.location + '';
   ref = ref.split('?')[0];
   current = current.split('?')[0]
   
   // if we've landed on this page from direct traffic / referal we can't go back to the previous page, so we'll go to the homepage
   /*if (ref  === current)
      window.location = '/'
   else {
    if (document.referrer.indexOf('?') > 0 )
      window.location = document.referrer + '&' + Math.random();
    else
      window.location = document.referrer + '?&' + Math.random();
   }*/

      new URLSearchParams(props.location.search).get("adults")
   // if query string parameters contain dates & destination, redirect user to destination page, otherwise send to homepage
   if ( new URLSearchParams(props.location.search).get("cid") && 
        new URLSearchParams(props.location.search).get("out") && 
        new URLSearchParams(props.location.search).get("in") &&
        new URLSearchParams(props.location.search).get("gid") ) {

            const gid = new URLSearchParams(props.location.search).get("gid");
            const cid = new URLSearchParams(props.location.search).get("cid");
            const out = new URLSearchParams(props.location.search).get("out");
            const in_ = new URLSearchParams(props.location.search).get("in");
            const adults = new URLSearchParams(props.location.search).get("a");
            const teens = new URLSearchParams(props.location.search).get("t");
            const children = new URLSearchParams(props.location.search).get("c");
            const infants = new URLSearchParams(props.location.search).get("i");

            // rediect to this page: https://www.afnu.co.il/דילים/נוספים/?destinations=${cid}&adults=${adults}&children=${children}&infants=${infants}&teens=${teens}&from_date=${out}&to_date=${in_}&dates_type=exact&search=pax
            
            // if there are more than 6 months till the end of this year, extract the existing year, otherwise return current year+next year names
            let yearRangeString = new Date(in_).getFullYear();
            
            window.location = `/דילים/${yearRangeString}/?destinations=${cid}&adults=${adults}&children=${children}&infants=${infants}&teens=${teens}&from_date=${out}&to_date=${in_}&dates_type=exact`;
            
    } else {
        window.location = '/';
    }          

    showPageNotFoundPopup(false);
  }

  const handleOkQuantityNotFound = () => {
    /*
    // window.location = '/';
    
    // When go back from popup (after package not found), force homepage to refresh
    let ref = document.referrer?document.referrer+'':'';
    let current = window.location + '';
    
    // if we've landed on this page from direct traffic / referal we can't go back to the previous page, so we'll go to the homepage
    if (ref  == current)
       window.location = '/'
    else {
     if (document.referrer.indexOf('?') > 0 )
       window.location = document.referrer + '&' + Math.random();
     else
       window.location = document.referrer + '?&' + Math.random();
    }
    */
    setOpenQuantityNotFound(false);
   }


  /**
   * * Handle passengers change
   * 
   * @param {*} values 
   * @returns 
   */
  const HandleQuantityChange = async (values) => {

    changePassengerQuantity(values)
    setDetailState(false);
    

    /*let resPackage = await axios.get(
      `${API_ENDPOINT}/search/packages/${QSPackageUID}/quote?adults=${values?.adults}&infants=${values?.infants}&children=${values?.children}&seniors=${values?.seniors}`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );
    */
    //console.log(resPackage)
    const lastDetailState = detailState;

    /*if (!resPackage?.data || resPackage?.data?.error) {
    
      //showPageNotFoundPopup(true);
      setErrorMessage(`לא נמצאה חבילה מתאימה`);
      setOpenError(true);

      setDetailState(lastDetailState);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ModalPackageNotFound',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });
    } else */
    {
      //window.location= `/deal/${resPackage?.data?.uid}/`;
      //return


      // Update GUI with new package details & pricing
      //setDetailState(resPackage?.data);

      const packageDetails = await loadPackage(QSPackageUID, false, values?.rooms, false, true); /*{ 
        adults: values?.adults?values?.adults:2,
        children: values?.children?values.children: 0,
        infants: values?.infants?values.infants: 0,
      });*/

      if (!packageDetails || packageDetails.error) {
        console.log(packageDetails?.error)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ModalQuantityNotFound',
            'pageUrl': window.url,
            'pageTitle' : window.title
        });

        setDetailState(lastDetailState); // reload last page details
        setOpenQuantityNotFound(true); // show popup

        return false;
      }

      history.push(`/deal/${packageDetails.uid}/`);

      // Update GUI with deal details
      setDetailState(packageDetails);
      //quote_uid = packageDetails?.quote;


      // Update ROOM
      /*let resRoom = await axios.get(
        `${API_ENDPOINT}/search/packages/${QSPackageUID}/rooms`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );

          //   console.log(selectedRoomContext)
    if(selectedRoomContext){
      setDetailRoomState(selectedRoomContext);
      let sr = selectedRoomContext.find((item) => {
        setRoomUID(item.uid);
        return item?.best_offer == true;
      });
      setSelectedRoom(sr);
      
    }
    else{
      setDetailRoomState(resRoom?.data);
      let sr = resRoom?.data.find((item) => {
        setRoomUID(item.uid);
        return item?.best_offer == true;
      });
      setSelectedRoom(sr);
      
    }
    */

    // reset hotel price so we'll see the preloader and then call the RT check
    setRTCheckHotelState(null)

    // Perform RT check
    intervalCheckFlightAndHotel(packageDetails, packageDetails.pax.adults, packageDetails.pax.children, packageDetails.pax.infants);

    console.log('Quantity Change')
    }


    


  
  }; // handleQuanityChagne

  const handleShowMap = () => {
    scroller.scrollTo('HotelMap', {
      duration: 300,
      delay: 0,
      smooth: true,
      //containerId: this.state.city,
      //offset: 50, // Scrolls to element + 50 pixels down the page
    });
    setShowMap(true);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'showMapLinkClick',
        'pageUrl': window.location,
        'pageTitle' : window.title,
    });


  };

  const handleChangeRoom = (room, packageUid) => {
    
      setAccordonView(true);
      setOpenAccordon(false);
      let roomsArr = [];
      detailRoomState.map((item) => {
        if (item.uid === room.uid) {
          roomsArr.push({ ...item, best_offer: true, selected: true });
          setRoomUID(item.uid)
        } else {
          roomsArr.push({ ...item, best_offer: false, selected:false });
        }

        history.push(`/deal/${packageUid}?room=${room.uid}`); 
      });


      setDetailRoomState(roomsArr);
      // setDetailState({
      //   ...detailState,
      //   price:
      //     parseFloat(detailState?.price) + parseFloat(room?.price_difference),
      // });
      // setDetailState({
      //   ...detailState,
      //   price:
      //     parseFloat(detailState?.price) + parseFloat(room?.price_difference),
      // });
      changeSelectedRoom(roomsArr)
      setSelectedRoom(room);
  };

  const handleChangeFlight = async (e, packageUid, packagePrice, packagePassengersCount) => {

    setOpenChangeFlightModal(e, true);
    
    const packagePricePP = packagePrice / packagePassengersCount;

    console.log(packagePassengersCount)
    console.log(packagePrice)
    const resUpdateQuote = await axios.get(
        `${API_ENDPOINT}/search/packages/${packageUid}/flights`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          timeout: 10000
        }
    ); 

    setFlights(resUpdateQuote.data)
    setPackagePricePP(packagePricePP);

  }

  const clickCount = useRef(0);
  const timer = useRef(null);
  
  const handleChangeProperty = async (e, packageUid, packagePrice, packagePassengersCount,  mealPlanOnly) => {
    
    clickCount.current += 1;

    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(async() => {
      if (clickCount.current === 3) {
        //
        setChangeMeanPlanOnly(mealPlanOnly)
        setOpenChangePropertyModal(e, true);

        const packagePricePP = packagePrice / packagePassengersCount;

        const resUpdateQuote = await axios.get(
            `${API_ENDPOINT}/search/packages/${packageUid}/properties`,
            {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
            timeout: 10000
            }
        ); 

        setProperties(resUpdateQuote.data)
        setPackagePricePP(packagePricePP);

      }
      clickCount.current = 0; // Reset click count
    }, 500); // Time window to detect triple click
  };
    
    


  const handleImageChange = (index) => {
    setImageLoading(true);
    setCounter(index + 1);
  };
  
  const handleImageLoad = (loading) => {
    setImageLoading(false);
  };

  const handleChangeAccordon = (panel) => (event, isExpanded) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'OpenAccordion',
        'pageUrl': window.location,
        'pageTitle' : window.title,
        'panel': panel,
        'label': panel
    });
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmitBasic = (e) => {
    setOpenYesNo(e, true);
  }

  const GetMealPlanCode = (code) => {
    let local_meal_plan_code='RO';
    
    switch(code) {
        case 'SA'||'SP'||'SC': local_meal_plan_code='RO';break; // Accommodation only
        case 'HD': local_meal_plan_code='BB';break;             // B&B 
        case 'HM'||'MB'||'MP': local_meal_plan_code='HB';break; // HAlf Board
        case 'PC'||'PV': local_meal_plan_code='FB';break;       // Full board
        case 'TI'||'TP': local_meal_plan_code='AI';break;       // All Inclusive
        default: local_meal_plan_code='RO'
    }

    return local_meal_plan_code;   
  }

  
    /**
     * Calculate the number of days / weeks / months between the departure date & today
     * @param {*} departure_date 
     */
    const GetTimeToDeparture = (departure_date) => {
      try {
          const today = new Date();
          const [datestr, timestr] = (departure_date+'').split("T");
          const [year, month, day] = datestr.split("-");
          const departureDate = new Date(+year, +month - 1, +day);

          const difference = departureDate.getTime() - today.getTime();

          const daysToDeparture = Math.ceil(difference / (1000 * 60 * 60 * 24));
          
          switch (true) {
              case daysToDeparture <= 2: return '1-2 days';break;
              case daysToDeparture <= 4: return '3-4 days';break;
              case daysToDeparture <= 7: return '5-7 days';break;
              case daysToDeparture <= 10: return '8-10 days';break;
              case daysToDeparture <= 13: return '11-13 days';break;
              case daysToDeparture <= 21: return '2 weeks';break;
              case daysToDeparture <= 28: return '3 weeks';break;
              case daysToDeparture <= 35: return '4 weeks';break;
              case daysToDeparture <= 42: return '5 weeks';break;
              case daysToDeparture <= 49: return '6 weeks';break;
              case daysToDeparture <= 56: return '7 weeks';break;
              case daysToDeparture <= 63: return '8 weeks';break;
              case daysToDeparture <= 70: return '9 weeks';break;
              case daysToDeparture <= 100: return '2 months';break;
              case daysToDeparture <= 130: return '3 months';break;
              case daysToDeparture <= 160: return '4 months';break;
              case daysToDeparture <= 190: return '5 months';break;
              case daysToDeparture <= 220: return '6 months';break;
              case daysToDeparture <= 250: return '7 months';break;
              case daysToDeparture <= 280: return '8 months';break;
              case daysToDeparture <= 310: return '9 months';break;
              case daysToDeparture > 310: return '10+ months';break;
              default: return '';
          }
      } catch(e) {
          return 'ERROR'
      }
  }


  /**
   * handleSubmitBasicProcess
   * @param {*} e 
   */
  const handleSubmitBasicProcess = (e) => {

    if (selectedRoom?.price_difference && selectedRoom?.price_difference>0) {
        detailState.price =  parseFloat(detailState?.price) + parseFloat(selectedRoom?.price_difference)
        setDetailState(detailState);
    }
    
    preserveData(detailState, selectedRoom);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'ReserveBasicDeal',
        'pageUrl': "/deal/"+ detailState.uid +"/book/" + detailState.quote + `?type=basic`,
        'pageTitle' : window.title
    });

    window.dataLayer.push({
      event: 'Pageview',
      'pageUrl': "/deal/"+ detailState.uid +"/book/" + detailState.quote + `?type=basic`,
      'pageTitle' : window.title
    });

    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: detailState?.currency,
        value: rtCheckHotelState?.package?.pricing?.basic?.price, //detailState?.price,
        items: [
        {
          item_id: detailState?.uid,
          item_name: detailState?.code,
          index: 0,
          discount: 0,
          item_brand: detailState?.property?.city_name, //detailState?.destination?.city.name = Hebrew name
          item_category: `adults:${detailState?.pax?.adults},teens:${detailState?.pax?.teens},children:${detailState?.pax?.children},infants:${detailState?.pax?.infants},rooms:${detailState?.property?.rooms_count}`,
          item_category2: "stars: " + detailState?.property?.stars + " meals: " + GetMealPlanCode(detailState?.property?.meal_code),
          item_category3: "nights: " + detailState?.nights,
          item_category4: "departure: " + GetTimeToDeparture(detailState?.departure?.date),
          item_category5: "month: "+ detailState?.departure?.date.split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
          item_list_id:   "deals",
          item_list_name: "Deals",
          item_variant:   "standard",
          location_id: detailState?.destination?.city.id,
          price: rtCheckHotelState?.package?.pricing?.basic?.price,//detailState?.price,
          quantity: 1
        }
        ]
      }
    });
  
    setTimeout(function() {
      history.push("/deal/"+ detailState.uid +"/book/" + detailState.quote + `?type=standard`);
    }, 500);
  }

  /**
   * handleSubmitFlex
   * @param {*} e 
   */
  const handleSubmitFlex = async(e) => {
    // Update quote
    try {
      setSubmitFlexButtonLoading(true);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ReserveFlexDeal',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });
      window.dataLayer.push({
        event: 'Pageview',
        'pageUrl': "/deal/"+ detailState.uid +"/book/" + detailState.quote + `?type=flex`,
        'pageTitle' : window.title
      });

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: detailState?.currency,
          value: rtCheckHotelState?.package?.pricing?.flex?.price,//detailState?.price,
          items: [
          {
            item_id: detailState?.uid,
            item_name: detailState?.code,
            index: 0,
            discount: 0,
            item_brand: detailState?.property?.city_name, // detailState?.destination?.city.name = HEBREW NAME
            item_category: `adults:${detailState?.pax?.adults},teens:${detailState?.pax?.teens},children:${detailState?.pax?.children},infants:${detailState?.pax?.infants},rooms:${detailState?.property?.rooms_count}`,
            item_category2: "stars: " + detailState?.property?.stars + " meals: " + GetMealPlanCode(detailState?.property?.meal_code),
            item_category3: "nights: " + detailState?.nights,
            item_category4: "departure: " + GetTimeToDeparture(detailState?.departure?.date),
            item_category5: "month: "+ detailState?.departure?.date.split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
            item_list_id:   "deals",
            item_list_name: "Deals",
            item_variant:   "flex",
            location_id: detailState?.destination?.city.id,
            price: rtCheckHotelState?.package?.pricing?.flex?.price,//detailState?.price,
            quantity: 1
          }
          ]
        }
      });
      
      const resUpdateQuote = await axios.post(
        `${API_ENDPOINT}/quote/${detailState.quote}/add/flex`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          timeout: 10000
        }
      ); 

      if (await resUpdateQuote.data.status==200) {
        // Go to order form

        
        if (selectedRoom?.price_difference && selectedRoom?.price_difference>0) {
            detailState.price =  parseFloat(detailState?.price) + parseFloat(selectedRoom?.price_difference)
            setDetailState(detailState);
        }
        
        preserveData(detailState, selectedRoom);

        setTimeout(function() {
          history.push("/deal/"+ detailState.uid +"/book/" + detailState.quote);
        }, 500);
        
      } else {
          setErrorMessage('ארעה תקלה לא צפויה. אנא נסו שוב בעוד מספר דקות');
          setOpenError(true);

      }
    } catch(e) {
      // If connection timed out, let try again
          setErrorMessage(`ארעה שגיאה, אנא נסו שוב בעוד מספר דקות<br />${e.message}`);
          setOpenError(true);

      if (e.code==`ECONNABORTED`) {
          setErrorMessage(`תקלת תקשורת. אנא נסו שוב בעוד <br />${e.code}`);
          setOpenError(true);
      }
    }
  }

  /**
   * handleSubmitTG
   * @param {*} e 
   */
  const handleSubmitTG = async(e) => {
    
    
    // Update quote
    try {
      setSubmitTGButtonLoading(true);
        
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'ReserveFlexDeal',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });
      window.dataLayer.push({
        event: 'Pageview',
        'pageUrl': "/deal/"+ detailState.uid +"/book/" + detailState.quote + `?type=flex`,
        'pageTitle' : window.title
      });

      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: detailState?.currency,
          value: rtCheckHotelState?.package?.pricing?.flex?.price,//detailState?.price,
          items: [
          {
            item_id: detailState?.uid,
            item_name: detailState?.code,
            index: 0,
            discount: 0,
            item_brand: detailState?.property?.city_name, // detailState?.destination?.city.name = HEBREW NAME
            item_category: `adults:${detailState?.pax?.adults},teens:${detailState?.pax?.children},teens:${detailState?.pax?.children},infants:${detailState?.pax?.infants},rooms:${detailState?.property?.rooms_count}`,
            item_category2: "stars: " + detailState?.property?.stars + " meals: " + GetMealPlanCode(detailState?.property?.meal_code),
            item_category3: "nights: " + detailState?.nights,
            item_category4: "departure: " + GetTimeToDeparture(detailState?.departure?.date),
            item_category5: "month: "+ detailState?.departure?.date.split("-", 2).join("-"), // remove everything after second dash occurance (which converts the date from 2023-07-29T00:00:00.000Z to 2023-07),
            item_list_id:   "deals",
            item_list_name: "Deals",
            item_variant:   "trip-guaranty",
            location_id: detailState?.destination?.city.id,
            price: rtCheckHotelState?.package?.pricing?.flex?.price,//detailState?.price,
            quantity: 1
          }
          ]
        }
      });
      
      const resUpdateQuote = await axios.post(
        `${API_ENDPOINT}/quote/${detailState.quote}/add/trip-guaranty`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
          timeout: 10000
        }
      ); 

      if (await resUpdateQuote.data.status==200) {
        // Go to order form
        
        if (selectedRoom?.price_difference && selectedRoom?.price_difference>0) {
            detailState.price =  parseFloat(detailState?.price) + parseFloat(selectedRoom?.price_difference)
            setDetailState(detailState);
        }
        
        preserveData(detailState, selectedRoom);

        setTimeout(function() {
          history.push("/deal/"+ detailState.uid +"/book/" + detailState.quote);
        }, 500);
        
      } else {
          setErrorMessage('ארעה תקלה לא צפויה. אנא נסו שוב בעוד מספר דקות');
          setOpenError(true);

      }
    } catch(e) {
      // If connection timed out, let try again
          setErrorMessage(`ארעה שגיאה, אנא נסו שוב בעוד מספר דקות ${e.message}`);
          setOpenError(true);

      if (e.code==`ECONNABORTED`) {
          setErrorMessage(`תקלת תקשורת. אנא נסו שוב בעוד <br />${e.code}`);
          setOpenError(true);
      }
    }
  }
  
  const handleSubmit = (packageType, showConfirmDialog) => {
    if (showConfirmDialog) {
      setOpenYesNo(true);
    } else {
      detailState.price =  parseFloat(detailState?.price) + parseFloat(selectedRoom?.price_difference)
      setDetailState(detailState);
      preserveData(detailState, selectedRoom);
      history.push("/deal/"+ detailState.uid +"/book/" + detailState.quote + `?type=${packageType}`);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          event: 'Reserve'+packageType+'Deal',
          'pageUrl': window.url,
          'pageTitle' : window.title
      });
      
    //  window.location = ("/quote/" + detailState.quote);
    }

  };

  const handleYes = () => {
    setOpenYesNo(false)
    setSubmitBasicButtonLoading(true);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'ReserveBasicDealConfrmationYesButton',
        'pageUrl': window.location,
        'pageTitle' : window.title
    });

    handleSubmitBasicProcess()
  }

  const handleNo = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'ReserveBasicDealConfrmationNoButton',
        'pageUrl': window.location,
        'pageTitle' : window.title
    });

    setOpenYesNo(false)
  }

  const getCurrencySymbol = (cur) => {
    switch(cur) {
      case 'USD': return `$`;
      case 'EUR' || 'EURO': return `€`;
      case 'ILS' || 'NIS': return `₪`;
    }
  }

  const showPageNotFoundPopup = (show) => {
    setOpenPackageNotFound(show);
    // after 5 seconds automatically invoke the function handleOkGoToHomepage
    setTimeout(() => {
      handleOkGoToHomepage();
    }, 5000);
  }

  //const packageBasePrice = parseFloat(parseFloat(detailState?.price));
  //const passengersCount = detailState?.passengers_count;
  
  var date = new Date(detailState?.flight?.outbound?.arrival_date);
  let selectedCancelationDate = date.setHours(
    date.getHours() - selectedRoom?.cancel_policy?.penalty_hours_before
  );
  
  selectedCancelationDate = moment.parseZone(selectedCancelationDate).format(
    "DD/MM/YYYY"
  );
  let ratingArr = [1, 2, 3, 4, 5, 6];
  //console.log(detailState)

  // If we're less than 8 days before checkin, don't offer flex 
  const today = moment();
  const outboundDeparture = detailState?.flight?.outbound?.departure_date;
  //const hideFlex = today.add(8,'days').isSameOrAfter(moment(outboundArrival)); 
  const hideFlex = today.add(17, 'days').isSameOrAfter(moment(outboundDeparture)); // || moment(outboundDeparture).isBefore(moment('2024-11-01'));
  //console.log(rtCheckHotelState)
  
  // Load query string parameters or set default values
  let adults =   new URLSearchParams(props.location.search).get("adults")?new URLSearchParams(props.location.search).get("adults")*1:0;
  let teens =    new URLSearchParams(props.location.search).get("teens")?new URLSearchParams(props.location.search).get("teens")*1:0;
  let children = new URLSearchParams(props.location.search).get("children")?new URLSearchParams(props.location.search).get("children")*1:0;
  let infants =  new URLSearchParams(props.location.search).get("infants")?new URLSearchParams(props.location.search).get("infants")*1:0;

  if (!new URLSearchParams(props.location.search).get("adults") && new URLSearchParams(props.location.search).get("a")) {
    adults =   new URLSearchParams(props.location.search).get("a")*1;
    teens =    new URLSearchParams(props.location.search).get("t")?new URLSearchParams(props.location.search).get("t")*1:0;
    children = new URLSearchParams(props.location.search).get("c")?new URLSearchParams(props.location.search).get("c")*1:0;
    infants =  new URLSearchParams(props.location.search).get("i")?new URLSearchParams(props.location.search).get("i")*1:0;
  }


  let PaxCount = adults + teens + children + infants; // rtCheckHotelState?.pax?.adults + rtCheckHotelState?.pax?.children + rtCheckHotelState?.pax?.infants;

  // Combine teens + children
  //children = children + teens;
  

  // If no pax in query string, use the package's pax
  if (!PaxCount || PaxCount == 0) {
    console.log(detailState)
    adults = detailState?.pax?.adults*1;
    children = detailState?.pax?.children*1;
    infants =detailState?.pax?.infants*1;
    PaxCount = adults + children + infants;
  }
/*  if ((adults + children + infants > 0) && rtCheckHotelState?.package?.passengers_count==(adults + children + infants)) {
    rtHotelPaxCount = rtCheckHotelState?.package?.passengers_count;
    // If both cached pacakage and new requested pax count are the same, make sure that also the per pax type is the same 
    //if (rtCheckHotelState?.pax?.adults==adults && rtCheckHotelState?.pax?.children==children && rtCheckHotelState?.pax?.infants==infants)
      
  }
  */

  const cityID = detailState?.destination?.city?.id;
  const cityName = detailState?.destination?.city?.name;
  const fromDate = detailState?.departure?.date.split('T')[0];
  const toDate = detailState?.return?.date.split('T')[0];
  const similarHotelsURL = `/${cityName}/${fromDate}-${toDate}/?adults=${adults}&teens=${teens}&children=${children}&infants=${infants}&destinations=${cityID}&from_date=${fromDate}&to_date=${toDate}`;


  
  //const flexPrice = 1;
  //const flexPrice = detailState?.special_pricing.filter(s=>s.code==flexType).price.amount;
  /*const flexPrice = detailState?.special_pricing.find(s=>s.code==flexType).price.amount;
  const flexAmount = detailState?.special_pricing.find(s=>s.code==flexType).refund.amount;
  const flexRefundPercents = detailState?.special_pricing.find(s=>s.code==flexType).refund.percent;
  const flexExpirationHours = detailState?.special_pricing.find(s=>s.code==flexType).refund.expiration_hours;
  const flexRefundIncludesBags = detailState?.special_pricing.find(s=>s.code==flexType).refund.width_bags;
  const flexRefundIncludesCost = detailState?.special_pricing.find(s=>s.code==flexType).refund.with_cost;*/

  return (
    <div className="packageDetails">
      <div style={{color:'#fff', fontSize:'1px'}} tabIndex={1}>במידה והינכם סובלים ממגבלה המקשה עליכם לבצע את ההזמנה באתר, תוכלו ליצור קשר עם מחלקת השירות שלנו על ידי שליחת הודעת וואטסאפ למספר 050-7801801 ולציין את קוד ההזמנה: {detailState?.code}</div>

      <MainNavigationBar fixed="true" />
      
      <ChangeFlightModal
        open={openChangeFlightModal}
        packagePricePP={packagePricePP}
        onClose={handleCloseChangeFlight}
        flights={flights}
        packageUid={detailState?.uid}
        originalFlightInventoryUID={detailState?.flight?.inventory_uid}
        //onSubmit={handleSubmitChangeFlight}
      />

      <ChangePropertyModal
        open={openChangePropertyModal}
        packagePricePP={packagePricePP}
        onClose={handleCloseChangeProperty}
        properties={properties}
        packageUid={detailState?.uid}
        originalPropertyInventoryUIDs={detailState?.property?.inventory_uids}
        originalPropertyUID={detailState?.property?.uid}
        changeMealPlanOnly={changeMealPlanOnly}
        //onSubmit={handleSubmitChangeFlight}
      />

      <YesNoModal
        openYesNo={openYesNo}
        onYes={handleYes}
        onNo={handleNo}
      />

      <ErrorModal
        errorMessage={errorMessage}
        openError={openError}
        onClose={handleClose}
      />

      <FlexDetailsModal
        open={openFlexDetailsModal}
        onClose={handleCloseFlexDetails}
        expiration_hours={flexExpirationHours}
        type={flexType}
      />

      <TermsModal
        link={termLink}
        open={termsModal}
        onClose={handleCloseTerms}
      />{" "}
      
      <PriceChangeUpModal
        priceChangeMessage={priceChangeMessage}
        openPriceChangeUp={openPriceChangeUp}
        onOk={handleOk}
      />

      <PriceChangeDownModal
        priceChangeMessage={priceChangeMessage}
        openPriceChangeDown={openPriceChangeDown}
        onOk={handleOk}
      />

      <PackageNotFoundModal
        openPackageNotFound={openPackageNotFound}
        onOk={handleOkGoToHomepage}
      />

      <QuantityNotFoundModal
        openQuantityNotFound={openQuantityNotFound}
        onOk={handleOkQuantityNotFound}
      />

      <PassengersChangeWarningModal
        passengersChangeMessage={passengersChangeMessage}
        openPassengersChangeWarning={openPassengersChangeWarning}
        onClosePassengersChangeWarning={handleClosePassengersChangeWarning}
      />
      {detailState && Object.keys(detailState).length > 0  ? (
        <div className="container ugly">

          <Navbar name="חזרה לכל הדילים" previous='/' tabIndex={2} style={{fontSize:'1em'}} />
          
          <QuantityModal
            open={open}
            onClose={handleClose}
            pax={detailState?.pax}
            rooms={detailState?.property?.rooms_occupancies}
            handleQuantityChange={HandleQuantityChange}
          />

          {/* Package Details */}
          <div className="form-bg mb-4 first_div" tabIndex={3}>
            <h2><strong>
              {detailState?.origin?.country.name}
              <ChevronLeft  style={{ transform: "rotate(0deg)" }} />
              {detailState?.destination?.city.name} ({detailState?.destination?.country.name}){" "}
            </strong></h2>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8} style={{fontSize:`1.2em`}}>
                    
                    <Grid container>

                      
                      <Grid item xs={2}  sm={1} md={1}>
                        <AirplanemodeActiveIcon style={{width:"0.9em",height:"0.9em", rotate:"-90deg"}} alt="טיסות ישירות בלבד" title="טיסות ישירות בלבד"/>
                      </Grid>
                      <Grid item xs={10} sm={11} md={11}>טיסות ישירות</Grid>

                      {detailState?.property?.rooms_count > 0 ? (<>
                        <Grid item xs={2} sm={1} md={1}><HotelIcon style={{width:"0.85em",height:"0.85em"}}  alt={detailState?.property?.stars +' כוכבים'} title={detailState?.property?.stars +' כוכבים'} /></Grid>
                        <Grid item xs={10} sm={11} md={11}>{detailState?.property?.rooms_count>1?detailState?.property?.rooms_count+'  חדרים במלון':' מלון '} {detailState?.property?.stars +' כוכבים'}</Grid></>):<span />}
                    
                      {/*<Grid item xs={2} sm={1} md={1}>
                        <HotelIcon style={{width:"0.85em",height:"0.85em"}} />
                      </Grid>
                      <Grid item  xs={10} sm={11} md={11}>כולל מלון</Grid>
                      */}

                      {detailState?.pax?.adults > 0 ? (<>
                        <Grid item xs={2} sm={1} md={1}><PeopleAltIcon style={{width:"0.85em",height:"0.85em"}} alt={`${detailState?.pax?.adults} מבוגרים`} title={`${detailState?.pax?.adults} מבוגרים`} /></Grid>
                        <Grid item xs={10} sm={11} md={11}>{detailState?.pax?.adults} מבוגרים</Grid></>):<span />}

                      {detailState?.pax?.children > 0 ? (<>
                        <Grid item xs={2} sm={1} md={1}><PeopleAltIcon style={{width:"0.85em",height:"0.85em"}} /></Grid>
                        <Grid item xs={10} sm={11} md={11}>{detailState?.pax?.children + ((detailState?.pax?.teens)?(detailState?.pax?.teens):0)} ילדים (בגילאי 2-15 שנים)</Grid></>):<span />}

                      {detailState?.pax?.infants > 0 ? (<>
                        <Grid item xs={2} sm={1} md={1}><ChildFriendlyIcon style={{width:"0.85em",height:"0.85em"}} /></Grid>
                        <Grid item xs={10} sm={11} md={11}>{detailState?.pax?.infants} תינוקות (בגילאי 1-23 חודש)</Grid></>):<span />}

                      
                      </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container  space={2} style={{textAlign:`left`}}>
                      <Grid item xs={12}>
                          <Button
                            className="composition-btn"
                            onClick={gotoChangePaxPage}
                          style={{lineHeight:1}}>
                            שנה כמות נוסעים
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                      {/*<p>קוד הזמנה: <span style={{color:"#cf5770"}}>{detailState?.code}</span></p>*/}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div> 
          
          {/* /Package Details */}


          {/* Cancellation }
          {
            detailState.ancillaries.map((item, index) => {
              if (item?.code=='cancellation_protection_included' && item?.included==true) {
                return (
                  <div className="form-bg mb-4 first_div" style={{border:`2px solid rgb(41 163 131 / 62%)`,fontWeight:`bold`}}>
                    <Grid container columns={25}>
                      <Grid item xs={2} sm={1} md={1}>
                        <ShieldIcon style={{ fontSize: "33px", color: "#29a383" }} />
                      </Grid>
                      <Grid item xs={10} sm={8} md={8}>
                      <h5 style={{color: `#29a383`,fontWeight: `bold`}}>מגן ביטול חופשה (ללא תוספת תשלום)</h5>
                      <h5><p>אצלנו אתם יכולים להזמין חופשה בשקט!</p>השירות מאפשר לבטל את ההזמנה עד <strong>{penaltyBeforeText}</strong> לפני מועד היציאה לחופשה ולקבל החזר כספי בסך  <strong>{refundValueText}</strong> ללא תנאים נסתרים ובלי אותיות קטנות.</h5>
      
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            })
          }
          { /Cancellation */}


          {/* Flights summary */}
          <div className="form-bg mb-4 pl_pr_none" style={{padding:`0`,fontSize:`1.2em`}}>
            <Grid container alignItems="stretch" justifyContent="space-evenly" tabIndex={4}>
              <Grid item xs={12} sm={2} md={2}>

                      <Grid container style={{textAlign:`center`,height:`100%`}} justifyContent="space-evenly" alignItems="stretch">
                        <Grid item xs={6} sm={12} md={12} className="flightHeaderFirstCol">
                          <Grid container justifyContent="space-evenly" alignItems="center" style={{height:`100%`,minHeight:`3rem`}}>
                              <Grid item>
                              <div><strong>הלוך</strong></div>
                              <strong>{moment.parseZone(detailState?.departure?.date).format('DD MMM, YYYY')}</strong>
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs className="flightHeaderSecondCol" >
                          <Grid container  justifyContent="space-evenly" alignItems="center" style={{height:`100%`, minHeight:`3rem`}}>
                            <Grid item xs={12} sm={12} md={12}>
                              <div><strong>חזור</strong></div>
                              <strong>{moment.parseZone(detailState?.return?.date).format("DD MMM, YYYY")}</strong>
                            </Grid>
                          </Grid>
                          
                        </Grid>
                      </Grid>

              </Grid>
              <Grid item xs={12} sm={10} md={10}>

                      <Grid container className="flightDetailsBox" direction="column">
                        <Grid item xs={12} sm={7} md={7}>
                          <Grid container style={{borderBottom:`2px solid lightgrey`, margin:'1em 0'}} justifyContent="space-around" alignItems="center">
                            <Grid item xs={2} title={` חברת התעופה בהלוך: ${detailState?.flight?.outbound?.airline?.name}`}>
                                <div style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                                    <img
                                        className=""
                                        alt={`לוגו חברת התעופה בהלוך: ${detailState?.flight?.outbound?.airline?.name}`}
                                        src={detailState?.flight?.outbound?.airline?.icon}
                                        height="30"
                                        width="30"
                                    />
                                    <div>{detailState?.flight?.outbound?.airline?.name}</div>
                                </div>
                            
                            </Grid>
                            <Grid item xs={3}>
                              {moment.parseZone(detailState?.flight?.outbound?.departure_date).format("HH:mm")}<br />
                              {detailState?.flight?.outbound?.departure_iata_name} {detailState?.flight?.outbound?.departure_iata}
                            </Grid>
                            <Grid item xs={4}>
                              <div className="text-center">
                                {goTime}
                                <hr style={{  height: "1px" }} />
                                <span className="greenText">טיסה ישירה</span>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              {moment.parseZone(detailState?.flight?.outbound?.arrival_date).format("HH:mm")}<br />
                              {detailState?.flight?.outbound?.arrival_iata_name} {detailState?.flight?.outbound?.arrival_iata}
                            </Grid>
                          </Grid>

                        </Grid>


                        <Grid item xs={12} sm={7} md={7}>

                          <Grid container  justifyContent="space-around" alignItems="center">
                            <Grid item xs={2} title={` חברת התעופה בחזור: ${detailState?.flight?.inbound?.airline?.name}`}>
                            <div style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                                    <img
                                        className=""
                                        alt={`לוגו חברת התעופה בחזור: ${detailState?.flight?.inbound?.airline?.name}`}
                                        src={detailState?.flight?.inbound?.airline?.icon}
                                        height="30"
                                        width="30"
                                        
                                    />
                                    <div>{detailState?.flight?.inbound?.airline?.name}</div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                              {moment.parseZone(detailState?.flight?.inbound?.departure_date).format("HH:mm")}<br />
                              {detailState?.flight?.inbound?.departure_iata_name} {detailState?.flight?.inbound?.departure_iata}
                            </Grid>
                            <Grid item xs={4}>
                              <div className="text-center">
                                  {returnTime}
                                  <hr style={{  height: "1px" }} />
                                  <span className="greenText">טיסה ישירה</span>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              {moment.parseZone(detailState?.flight?.inbound?.arrival_date).format("HH:mm")}<br />
                              {detailState?.flight?.inbound?.arrival_iata_name} {detailState?.flight?.inbound?.arrival_ata}
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>

              </Grid>
            </Grid>
            <div tabIndex={10}>
                <a onClick={(e)=>handleChangeFlight(e, detailState?.uid, detailState?.price, detailState?.passengers_count)} href='#' style={{textDecoration:'none'}} title="טיסות נוספות">
                    <div style={{backgroundColor: '#999', color:'#fff', fontSize:'1.1em', fontWeight:700, textAlign:'center', padding:'.5em', margin:'0 auto', borderRadius:'0 0 5px 5px', textDecoration:'underline'}}>הצג טיסות נוספות <FontAwesomeIcon icon={faAngleDoubleLeft} style={{display:'inline-block'}} /></div></a>
            </div>
          </div>
          {/* /Flights summary */}
          
          <div className="form-bg">
            <Grid container tabIndex={5}>
              <Grid item xs={12}>
                <h2 className="carrousal_title">
                  <p title="שם וכתובת המלון"><b>{detailState?.property?.name}</b></p>
                  <small>{detailState?.property?.address} {detailState?.property?.city_name}</small>
                </h2>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="carrousalBody">
                  <Carousel
                    onChange={(index) => handleImageChange(index)}
                    navButtonsProps={{
                      // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                      style: {
                        color: "#666666",
                        backgroundColor: "#ffffffa1",
                        borderRadius: 0,
                        margin: 0,
                      },
                    }}
                    indicatorIconButtonProps={{
                      style: {
                        display: "none", // 3
                      },
                    }}
                    stopAutoPlayOnHover={true}
                    autoPlay={false}
                    swipe={true}
                    animation="slide"
                    navButtonsAlwaysVisible="true"
                    NextIcon={<div title="הצג תמונה הבאה" style={{fontWeight:800}}>&#8249;<img /></div>}
                    PrevIcon={<div title="הצג תמונה קודמת" style={{fontWeight:800}}>&#8250;<img /></div>}
                  >
                    {detailState?.property?.photos.map((item, i) => (
                      <div className="carrousalDiv text-center" key={'carrousel_item'+i}>
                        <div
                          className="carousalImage" style={{background:`url(${"https://cdn.cookiecrumbz.com/images/properties/600/"+item})`}} title="גלריית תמונות של המלון" />
                        
                      </div>
                    ))}
                  </Carousel>
                </div>
                <span className="imgCounter" title={`מציג ${detailState?.property?.photos?.length} תמונות`}>
                  {counter}/{detailState?.property?.photos?.length}
                </span>
              </Grid>
              <Grid item
                xs={12}
                md={6}
                style={{ marginTop: "15px" }}
                className="iconsDiv"
              >
                <Grid
                  style={{ textAlign: "center"}}
                  className="icon_mob_ver"
                  container justifyContent="center"
                  alignItems="center"
                >
                <Grid item xs={6} title={`רמת אירוח ${stars} כוכבים`} tabIndex={6}>
                    <div className="leftSmall_div bb bl ">
                      <p style={{margin:0}}><strong>רמת אירוח</strong></p>
                      <label style={{fontSize:0,color:'#fff'}}>{stars} כוכבים</label>
                      {/* <Rating disabled name="size-small" defaultValue={stars} /> */}
                      {ratingArr?.map((item, index) => {
                        if (index < stars) {
                          return (
                            <StarIcon key={index+'stars'}
                              style={{ color: "#ffc772", height: "31px" }}
                            />
                          );
                        }
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={6} tabIndex={7}>
                    <div className="leftSmall_div bb" style={{lineHeight: '2.5'}}  onClick={(e)=>handleChangeProperty(e, detailState?.uid, detailState?.price, detailState?.passengers_count)}>
                      <b>דירוג גולשים</b>
                      <label style={{fontSize:0,color:'#fff'}}>{`דירוג גולשים: ${rating} מתוך 5`}</label>
                      <br />
                      <div title={`דירוג גולשים: ${rating} מתוך 5`}>
                      <StyledRating
                        name="customized-color"
                        disabled
                        aria-label=""
                        defaultValue={rating}
                        icon={<RadioButtonCheckedIcon fontSize="inherit" />}
                      /></div>
                      <p style={{ marginBottom: "-14px", marginTop: "-10px" }}>
                        <label>
                        {detailState?.property?.reviews_count && detailState?.property?.reviews_count > 2 ? detailState?.property?.reviews_count + ' ביקורות':''}
                        </label>
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={6} tabIndex={8}>
                    <div className="leftSmall_div bl "  onClick={(e) => handleShowMap()}>
                      <label style={{fontSize:0,color:'#fff'}}>{`המלון מרוחק `} {detailState?.property?.distance_from_center<1?detailState?.property?.distance_from_center*1000+' מטרים':(detailState?.property?.distance_from_center*1).toFixed(1) + ' קילומטרים'} {`מהמרכז`}</label>
                      <b>{detailState?.property?.distance_from_center<1?detailState?.property?.distance_from_center*1000+' מטר':(detailState?.property?.distance_from_center*1).toFixed(1) + ' ק"מ'}  מהמרכז
                      </b>
                      <br />
                      <LocationOnIcon title="צפה במפה"
                        style={{ fontSize: "33px", color: "#555555" }}
                      /><br />
                      <a style={{textDecoration:'none', color: '#1e5881', fontSize: '1.1em', cursor:'pointer'}}><u><strong>צפה במפה</strong></u></a>
                    </div>
                  </Grid>
                  <Grid item xs={6} tabIndex={9}>
                    <div className="leftSmall_div" onClick={(e)=>handleChangeProperty(e, detailState?.uid, detailState?.price, detailState?.passengers_count, true)} >
                      <>
                      <strong>{GetMealPlanName(detailState?.property?.meal_code)}</strong>
                        <br />
                        <span className="icon">
                          {detailState?.property?.meal_code=='SC'||detailState?.property?.meal_code=='SA'||detailState?.property?.meal_code=='SP'?
                          (<>
                            
                            <HotelIcon style={{width:`1.3em`,height:`1.3em`, color: `#555`}} alt="לינה בלבד" title="לינה בלבד" />
                            <label style={{fontSize:0,color:'#fff'}}> המלון כולל  </label>
                            </>
                          ):
                          detailState?.property?.meal_code=='HD'?
                            (<FreeBreakfast style={{width:`1.3em`,height:`1.3em`, color: `#555`}} alt="ארוחת בוקר כלולה" title="ארוחת בוקר כלולה" />):
                            (<img className="iconSize" src="/images/amenities/restaurant.svg" alt="ארוחת בוקר כלולה" />)}
                          </span>
                          <br />
                          
                          {detailState?.property?.meal_code=='SC'||detailState?.property?.meal_code=='SA'||detailState?.property?.meal_code=='SP'?
                          (''):
                          detailState?.property?.meal_code=='HD'?
                            ('כלולה במחיר'):
                            ('כלול במחיר')}
                      </>
                    </div>
                  </Grid>

                  {/*detailState?.property?.amenities.map((item, index) => {
                    if (index > 1) {
                      return (<></>)
                    } else {
                      if (index < 1) {
                        return (
                          <Grid item xs={6} className="desktopIcons">
                            <div className="leftSmall_div  bt bl ">
                              <b>{item?.name}</b>
                              <br />

                              <img className="iconSize" src={item?.icon} alt={item?.name} />
                            </div>
                          </Grid>
                        );
                      }
                      if (index >= 1 && index < 3) {
                        if (index == 1) {
                          return (
                            <Grid item xs={6}>
                              <div className="leftSmall_div bt desktopIcons ">
                                <b>{item?.name}</b>
                                <br />
                                {// <object type="image/svg+xml" data="https://www.afnu.co.il/images/amenities/hot-tub-solid.svg" className="logo"></object>
                                }
                                <img className="iconSize" src={item?.icon} alt={item?.name} />
                              </div>
                            </Grid>
                          );
                        }
                        return (
                          <Grid item xs={6}  className="desktopIcons">
                            <div className="leftSmall_div  bt">
                              <b>{item?.name}</b>
                              <br />
                              {
                                //<object type="image/svg+xml" data="https://www.afnu.co.il/images/amenities/hot-tub-solid.svg" className="logo"></object>
                              }
                              <img className="iconSize" src={item?.icon} alt={item?.name} />
                            </div>
                          </Grid>
                        );
                      }
                    }
                  })*/
                }
                </Grid>
              </Grid>
            </Grid>
           
          </div>

          <div tabIndex={10}>
            <a href={similarHotelsURL} style={{textDecoration:'none'}} title="מלונות נוספים ביעד הנבחר">
                <div style={{backgroundColor: '#999', color:'#fff', fontSize:'1.3em', fontWeight:700, textAlign:'center', padding:'.5em', margin:'0 auto', borderRadius:'0 0 5px 5px', textDecoration:'underline'}}>הצג מלונות נוספים <FontAwesomeIcon icon={faAngleDoubleLeft} style={{display:'inline-block'}} /></div></a>
          </div>

          <div className="rooms-bg">
            <Grid container tabIndex={11}>
              <Grid item xs={12}>
              <div
              className="icon_mob_ver"
              style={{ backgroundColor: "#fafafa" }}
              >
              <Grid className=" border_all" container tabIndex={12}>
                <Grid item xs={12}>
                  <label
                    style={{
                      fontSize: "22px",
                      marginRight: "2%",
                      fontWeight: "600",
                      color: "#555555",
                    }}
                    className="mb-2 mt-2"
                  >
                    החדרים הכלולים בהזמנה
                  </label>

                  {!accordonView ? (
                    <span className="cheapButton2">המשתלם ביותר</span>
                  ) : (
                    <span className="cheapButton2">חדר נבחר</span>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid className="cheapDiv " container>
                    <Grid item xs={12}>
                      {
                      detailRoomState && detailRoomState.length>0 ?
                        detailRoomState?.map((room, index) => {
                          // hide room header if we only have 1 room
                          var hideHeader = detailRoomState.length>1?'':'none'

                          return (
                            <div className="d-flex" key={`room_${index}`}>
                            <div className="blank-div"></div>
                            <div style={{ margin: "1% 2% 2% 2%" }}>
                              <h4 style={{display:hideHeader}}>חדר {index+1}</h4>
                              <h5>{room?room?.description:'Standard Room'}</h5>
                              <h5 className="pinkText">
                                <>
                                  {GetMealPlanName(room?room.supplier?.meal_plan_code:detailState?.property?.meal_code)}
                                  {room.supplier?.meal_plan_code=='HD'?' כלולה': detailState?.property?.meal_code=='HD'?' כלולה':''}
                                </>
                              </h5>
                              
                            </div>
                          </div>
                          )                        
                        })
                      :
                         (
                            <div className="d-flex" key={`room_1`}>
                            <div className="blank-div"></div>
                            <div style={{ margin: "1% 2% 2% 2%" }}>
                              <h5>Standard Room</h5>
                              <h5 className="pinkText">
                                <>
                                  {GetMealPlanName(detailState?.property?.meal_code)}
                                  {detailState?.property?.meal_code=='HD'?' כלולה':''}
                                </>
                              </h5>
                              
                            </div>
                          </div>
                        )
                      }
                      {/*selectedRoom && (
                        <div className="d-flex">
                          <div className="blank-div"></div>
                          <div style={{ margin: "1% 2% 2% 2%" }}>
                            <h5>
                              {selectedRoom?.description=='Room'?'Standard Room':selectedRoom?.description}
                            </h5>
                            <h5 className="pinkText">
                              <>
                                {GetMealPlanName(selectedRoom.supplier?.meal_plan_code)}
                                {selectedRoom.supplier?.meal_plan_code=='HD'?' כלולה': ''}
                              </>
                            </h5>
                            
                          </div>
                        </div>
                      )*/}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="xicon_mob_ver1" >
              <Grid container tabIndex={13}>
                <Grid item xs={12}>
                  <div className={classes.root}>
                    <Accordion
                      className="accordonBorder"
                      expanded={expanded === "panel1" && openAccordon}
                      onChange={handleChangeAccordon("panel1")}
                      onClick={(e) => setOpenAccordon(!openAccordon)}
                      // disabled={accordonView}
                      style={{display:`none`}}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <h4>
                            <b id={detailState?.property?.room?.uid}>סוגי חדרים נוספים</b>
                          </h4>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          {detailRoomState?.map((item, index) => {
                            // if (!item.best_offer) {
                            var d = new Date(
                              detailState?.flight?.outbound?.arrival_date
                            );
                            let cancelationDate = d.setHours(
                              d.getHours() -
                                item?.cancel_policy?.penalty_hours_before
                            );
                            cancelationDate =
                              moment.parseZone(cancelationDate).format("DD/MM/YYYY");

                            return (
                              <Grid key={'mealPlan_'+index}
                                className={`${
                                  index != detailRoomState.length - 1 && "bb"
                                } mt-3`}
                                container
                              >
                                <Grid item xs={6}>
                                  <Grid className="cheapDiv " container>
                                    <Grid item xs={12}>
                                      <div className="d-flex">
                                        {/*<div className="blank-div"></div>*/}
                                        <div style={{ marginRight: "2%" }}>
                                          <h4>
                                            <b>{item?.description}</b>
                                          </h4>
                                          <h5 className="pinkText">
                                            <>
                                              {item.breakfast_included
                                                ? "ארוחת בוקר כלולה"
                                                : "ארוחת הבוקר אינה כלולה"}
                                            </>
                                          </h5>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                <Grid container>
                                  <Grid item xs={12} md={6}>
                                    <div className="extrasDiv">
                                        <b>
                                          $
                                          {
                                            (detailState?.property?.room?.uid==item?.uid)?
                                            parseFloat(item?.price_difference).toFixed(2):
                                            parseFloat(item?.price_difference).toFixed(2)}
                                        </b>
                                      
                                        <br />
                                        תוספת
                                   
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    { item.uid == selectedRoomUID ? (
                                      <Button
                                        style={{
                                          height: "37px",
                                          width: "74px",   
                                          float: "left",
                                          backgroundColor: "#1e5881",
                                          color: "white",
                                        }}
                                        className="cheapButton"
                                        disabled={true}
                                      >
                                        {"נבחר"}
                                      </Button>
                                    ) : (
                                      <Button
                                        style={{
                                          height: "37px",
                                          width: "74px",
                                          backgroundColor: "#1e5881",
                                          color: "white",
                                          float:"left"
                                        }}
                                        className="cheapButton"
                                        onClick={(e) => handleChangeRoom(item, detailState?.uid)}
                                      >
                                        {"בחר"}
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>


                      


                    {/*detailState?.property?.amenities?.length > 0 && (
                      <Accordion
                        className="accordonBorder"
                        expanded={expanded === "panel2"}
                        onChange={handleChangeAccordon("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {" "}
                            <h4>
                              שירותי המלון
                            </h4>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            {detailState?.property?.amenities.map(
                              (item, index) => (
                                <Grid item xs={6} lg={3}>
                                  <div className="leftSmall_div text-center">
                                    <b>{item?.name}</b>
                                    <br />

                                    <img
                                      className="iconSize"
                                      src={item?.icon} 
                                      alt={item?.name}
                                    />
                                  </div>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                              )*/}
                    <Accordion
                        className="accordonBorder"
                        expanded={expanded === "panelmap" || showMap} 
                        onChange={handleChangeAccordon("panelmap")}
                      >
                        <AccordionSummary
                          title="לחץ לצפיה במיקום המלון על גבי המפה"
                          onClick={(e) => setShowMap(!showMap)}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>
                            {" "}
                            <h4  name="HotelMap">
                              מפה
                              <PlaceIcon style={{display:'none',width:"0.85em",height:"0.85em"}} />
                            </h4>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            { showMap?(<GMap lat={detailState?.property?.lat} lng={detailState?.property?.lng}></GMap>):<></>}
                            
                          </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {detailState?.property?.description?.length > 0 && (
                    <Accordion
                      title="מידע נוסף אודו המלון"
                      className="accordonBorder"
                      expanded={expanded === "panel3"}
                      onChange={handleChangeAccordon("panel3")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <h4>
                            מידע נוסף
                          </h4>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12}>
                            <p className="description mt-1" style={{direction:"ltr"}}>
                              {detailState?.property?.description}
                            </p>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    )}
                    {rtCheckHotelState?.property?.remarks?.length > 0 && (
                    <Accordion
                      className="accordonBorder"
                      expanded={expanded === "panel4"}
                      onChange={handleChangeAccordon("panel4")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <h4>
                            הערות
                          </h4>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12}>
                            <p className="description mt-1"  style={{direction:"ltr"}}>
                              {rtCheckHotelState?.property?.remarks}
                            </p>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    )}
                  </div>
                  
                </Grid>
              </Grid>
            </div>
            </div>
              </Grid>
            </Grid>
          </div>
          
          {1==2&&!rtCheckHotelState?.package?.pricing?
          <div className={'form-bg'} style={{textAlign:'center', padding:'.5em', marginBottom:'1.5em', color:'#666', fontWeight: 600}} tabIndex={14}>
            <h2 style={{padding:'1em 0 .5em'}}><strong> המערכת פונה לספקים שלנו בכדי לבדוק את המחירים העדכניים וזמינות החופשה.<br /><br />הבדיקה עשויה להמשך כדקה</strong></h2>
            <FontAwesomeIcon icon={faSpinner} spin size="4x" style={{margin:'0 auto .5em'}} />
          </div>
            :''}
          <div className={'form-bg mb-4 ' + (1==2 && !rtCheckHotelState?.package?.pricing?'hidden':'')} id="packageOptions">
            <h1><strong>בחרו את תנאי ההזמנה</strong></h1>
              <div className={'title-bar ' + hideFlex?'hidden':''}>
                <p>רק בעפנו מזמינים חופשה בראש שקט!</p>
                <p>בחרו בהזמנה גמישה על מנת  שתוכלו לבטל את ההזמנה ולקבל החזר כספי קבוע מראש, ללא הסברים ובלי אותיות קטנות</p>
              </div>
            <Grid container>
              

                {
                    // if detailState?.special_pricing array does not have any item whose code=='standard' then show the standard option
                    (!detailState?.pricing.find((item, idx) => item.code == 'standard'))?(
                        <Grid item xs={12} tabIndex={19}>
                            <div className=" mb-4 basicOption" style={{border:`1px solid #aaa`,fontWeight:`bold`}}>
                                <Grid container  alignItems="center" justifyContent="space-between" spacing={0}>
                                    <Grid item xs={8} className="content" >
                                        <h3>הזמנה רגילה</h3>
                                        <h5>ללא אפשרות ביטול</h5>
                                    </Grid>

                                    <Grid item xs={4} align="center">
                                        <h4>
                                            ${Math.ceil(detailState?.price / PaxCount) + ' '}  לנוסע</h4>
                                        {`בהרכב ${PaxCount} נוסעים`}
                                    </Grid>
                                </Grid>
                                <Grid container className="mt-1 mb-3" alignItems="center" justifyContent="space-between" spacing={0}>
                                    <Grid item xs={8} className="content" tabIndex={20}>
                                        קראתי ואני מאשר את &nbsp;
                                        <a href={detailState?.policies.cancellation.link} onClick={(e) => onOpenLink(e, detailState?.policies.cancellation.link)} title="תנאי ביטול המלאים של ההזמנה" style={{color:'#555'}}>תנאי הביטול</a>
                                    </Grid>
                                    <Grid item xs align="center">
                                        {!submitBasicButtonLoading?
                                            (
                                                <button tabIndex={21}
                                                title={'המשך להזמנה (ללא אפשרות ביטול)'}
                                                onClick={(e) => handleSubmitBasic(e)}
                                                className={'cancelButton'}>
                                                הזמנה רגילה</button>
                                            )
                                            :(
                                                <CircularProgress id="finishProgress" size="1.6rem" style={{color:'#1e5881'}}></CircularProgress>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ):('')
                }
                
                {
                    // Loop through all the speical_pricing options and present them
                    detailState?.pricing.map((item, idx) => {

                        if (item.code == 'standard') {
                            return (
                                <Grid item xs={12}  tabIndex={19}>
                                    <div className=" mb-4 basicOption" style={{border:`1px solid #aaa`,fontWeight:`bold`}}>
                                        <Grid container  alignItems="center" justifyContent="space-between" spacing={0}>
                                            <Grid item xs={8} className="content" >
                                                <h3>הזמנה רגילה</h3>
                                                <h5>ללא אפשרות ביטול</h5>
                                            </Grid>

                                            <Grid item xs={4} align="center">
                                                <h4>
                                                    ${Math.ceil(item.price.amount / PaxCount) + ' '}  לנוסע</h4>
                                                {`בהרכב ${PaxCount} נוסעים`}
                                            </Grid>
                                        </Grid>
                                        <Grid container className="mt-1 mb-3" alignItems="center" justifyContent="space-between" spacing={0}>
                                            <Grid item xs={8} className="content" tabIndex={20}>
                                                קראתי ואני מאשר את &nbsp;
                                                <a href={detailState?.policies.cancellation.link} onClick={(e) => onOpenLink(e, detailState?.policies.cancellation.link)} title="תנאי ביטול המלאים של ההזמנה" style={{color:'#555'}}>תנאי הביטול</a>
                                            </Grid>
                                            <Grid item xs align="center">
                                            {!submitBasicButtonLoading?
                                                (
                                                    <button tabIndex={21}
                                                    title={'המשך להזמנה (ללא אפשרות ביטול)'}
                                                    onClick={(e) => handleSubmitBasic(e)}
                                                    className={'cancelButton'}>
                                                    הזמנה רגילה</button>
                                                )
                                                :(
                                                    <CircularProgress id="finishProgress" size="1.6rem" style={{color:'#1e5881'}}></CircularProgress>
                                                )
                                            }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        }


                        if (item.code == 'flex') {
                            return (
                                <Grid item xs={12} className={hideFlex?'hidden':''} tabIndex={19}>
                                    <div className=" mb-4" style={{border:`3px solid #01bddddb`,fontWeight:`bold`}}>
                                        <Grid container>
                                            <Grid item xs={8} className="content" >
                                                <h3>הזמנה גמישה הכוללת אפשרות ביטול</h3>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="label">הבחירה המומלצת</span>     
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={8} className="content">           
                                                <ul>
                                                    <li>ביטול הזמנה מכל סיבה</li>
                                                    <li><span>החזר כספי <strong>בסך {'$'+Math.floor(item?.refund.amount/PaxCount)+' לנוסע '}</strong> </span></li>
                                                    <li><span>ביטול עד <strong>{item?.refund?.expiration_hours<24 ? 
                                                        item?.refund?.expiration_hours:
                                                        item?.refund?.expiration_hours/24} {item?.refund?.expiration_hours<24?'שעות ':' ימים '} </strong>
                                                        לפני היציאה מישראל</span></li>
                                                </ul>
                                                <br />
                                                <a style={{color:'#1e5881', fontWeight:800}} title="פרטים נוספים על הזמנה עם אפשרות ביטול" href="javascript:void()" onClick={(e) => handleClickOpenFlexDetails(e, item?.code, item?.refund?.expiration_hours)} tabIndex={20}>
                                                פרטים נוספים &gt;&gt;</a>
                                            </Grid>
                                            <Grid item xs={4} align="center">
                                                <h4>
                                                    ${Math.ceil(item.price.amount / PaxCount) + ' '}  לנוסע</h4>
                                                {`בהרכב ${PaxCount} נוסעים`}
                                            </Grid>
                                        </Grid>
                                        <Grid container className="mt-1 mb-3" alignItems="center" justifyContent="space-between" spacing={0}>
                                            <Grid item xs={8} className="content" tabIndex={20}>
                                                קראתי ואני מאשר את &nbsp;
                                                <a href={detailState?.policies.cancellation.link} onClick={(e) => onOpenLink(e, detailState?.policies.cancellation.link)} title="תנאי ביטול המלאים של ההזמנה" style={{color:'#555'}}>תנאי הביטול</a>
                                            </Grid>
                                            <Grid  item xs align="center">
                                                    {!submitFlexButtonLoading?
                                                        (
                                                            <button tabIndex={21}
                                                            title={hideFlex?'המשך להזמנה (ללא אפשרות ביטול)':'המשך לביצוע ההזמנה (כולל אפשרות ביטול)'}
                                                            onClick={(e) => handleSubmitFlex(e)}
                                                            className={'cancelButton'}>
                                                            הזמנה גמישה</button>
                                                        )
                                                        :(
                                                            <CircularProgress id="finishProgress" size="1.6rem" style={{color:'#1e5881'}}></CircularProgress>
                                                        )
                                                    }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        }

                        if (item.code == 'trip_guaranty') {
                            return (
                                <Grid item xs={12} tabIndex={19} className={hideFlex?'hidden':''}>
                                    <div className=" mb-4" style={{border:`3px solid #01bddddb`,fontWeight:`bold`}}>
                                        <Grid container>
                                            <Grid item xs={8} className="content" >
                                                <h3>הזמנה עם אפשרות ביטול עד 6 שעות לפני ההמראה</h3>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span className="label">הבחירה המומלצת</span>     
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={8} className="content">           
                                                <ul>
                                                    <li><span>ניתן לבטל <strong>מכל סיבה</strong>  עד {item?.refund?.expiration_hours<24 ? 
                                                        item?.refund?.expiration_hours:
                                                        item?.refund?.expiration_hours/24} {item?.refund?.expiration_hours<24?'שעות ':' ימים '} 
                                                    לפני היציאה מישראל</span></li>
                                                    <li><span>החזר כספי <strong>בסך 80% משווי ההזמנה</strong> 
                                                    {item?.refund?.with_bags?` (כולל הכבודה) `:('')}</span></li>
                                                    {!item?.refund?.width_cost?(<li>ההחזר אינו כולל את עלות השירות</li>):('')}
                                                </ul>
                                                <br />
                                                <a style={{color:'#1e5881', fontWeight:800}} title="פרטים נוספים על הזמנה עם אפשרות ביטול" href="javascript:void()" onClick={(e) => handleClickOpenFlexDetails(e, item?.code, item?.refund?.expiration_hours)} tabIndex={20}>
                                                פרטים נוספים &gt;&gt;</a>
                                            </Grid>
                                            <Grid item xs={4} align="center">
                                                <h4>
                                                    ${Math.ceil(item?.price?.amount / PaxCount) + ' '}  לנוסע</h4>
                                                {`בהרכב ${PaxCount} נוסעים`}
                                            </Grid>
                                        </Grid>
                                        <Grid container className="mt-1 mb-3" alignItems="center" justifyContent="space-between" spacing={0}>
                                            <Grid item xs={8} className="content" tabIndex={20}>
                                                קראתי ואני מאשר את &nbsp;
                                                <a href={detailState?.policies.cancellation.link} onClick={(e) => onOpenLink(e, detailState?.policies.cancellation.link)} title="תנאי ביטול המלאים של ההזמנה" style={{color:'#555'}}>תנאי הביטול</a>
                                            </Grid>
                                            <Grid  item xs align="center">
                                                {!submitTGButtonLoading?
                                                    (
                                                        <button tabIndex={21} style={{width: '90%'}}
                                                        title={'המשך להזמנה (כולל אפשרות ביטול עד 6 שעות מראש'}
                                                        onClick={(e) => handleSubmitTG(e)}
                                                        className={'cancelButton'}>
                                                        הזמנה גמישה</button>
                                                    )
                                                    :(
                                                        <CircularProgress id="finishProgress" size="1.6rem" style={{color:'#1e5881'}}></CircularProgress>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        }

                    })
                }

            </Grid>
          </div>
          
        </div>
      ) : (
        <div className={openError?'hide':'text-center mt-5'} style={{color:'#000', fontWeight:'400'}}>
          <Spinner alt="המתן לטעינת הדיל (מציג תמונות אווירה בזמן ההמתנה)"
            interval="2500" 
            images={['/images/destinations/krakow.jpg',
                    '/images/destinations/vienna.jpg',
                    '/images/destinations/antalya.jpg',
                    '/images/destinations/amsterdam.jpg']
            }>
            <h3 style={{padding:'0',fontWeight:'700', color:'#000', marginBottom:'0.5em'}}>מרגש! בחרתם חופשה <span style={{verticalAlign:'sub',color:'red',fontSize:'1.5em'}}>❤</span></h3>
            <p style={{fontWeight:'400', color:'#000'}}>כעת אנו בודקים את חופשתכם כדי להבטיח את החופשה המושלמת...<br />
            הבדיקה עשויה לקחת כדקה</p>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default Package;
