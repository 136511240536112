import React from 'react';
import configData from '../../../config/config.json';
import '../../../assets/css/App.css';
import Header from "../../../features/Layout/Header"
import Footer from "../../../features/Layout/Footer"
import Deck from "../Deck"
import Modal from 'react-responsive-modal';
import axios from 'axios';

//import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const API_ENDPOINT = configData.API_ENDPOINT;
const API_OBJECT = "/search/packages/";
const API_QUERY = "?lang=he";
const API_PREDEFINED_QUERY = "";

class Flights extends React.Component {

  constructor(props) {
    super(props);
    
    //let deals = JSON.stringify[{"uid":"5754a326-753d-4fc0-9a58-ca05990ff126"}];
    // Load query string parameters or set default values
    let deals  = ""; //new URLSearchParams(props.location.search).get("deals");

      deals = 
      JSON.stringify([
        {"uid":"00000000-753d-4fc0-9a58-ca05990ff126"}, // MOCKUP -
        /*{"uid":"5754a326-753d-4fc0-9a58-ca05990ff126"}, // DEV
        /*{"uid":"b740f8bf-9f00-4b4e-8ba2-d451828f7a70","price": 100}, // DEV
        
        {"uid":"5920a7f9-8db4-4b83-b81c-cbf0e24ad6ab","price": 150}, // naples
        {"uid":"f41748f5-6288-4d4e-bc4a-32d1154c496f","price": 171}, // bucharest
        {"uid":"cf9110ed-1350-4f9d-aa47-abe892673f8f","price": 100}, // venice
        {"uid":"9b262dd6-19c9-4799-ac5c-2000f591558b","price": 100}, // budapest
        {"uid":"0ddd1ecb-4ef4-4dc4-a4b1-0a275eed9f54","price": 100}, // varna
        {"uid":"431acd70-ea2c-4887-a71a-96fb6f26fff5","price": 100}, // rome
        {"uid":"068bfe04-4f17-42e0-94a4-d5ba74cca5e3","price": 199}, // sofia*/
      ])
  
    
      // load deals from external file /promotions.txt
      axios.get('/promotions/max199.txt').then(result=>{

      this.setState(prevState=>{
        return {
          ...prevState,
          deals: JSON.stringify(result.data)
        }
      })

      // this.state.deals = JSON.stringify(result.data);

      this.refetchData()

      return result
    })
/*      .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
      }
    })
    .then(data =>  {
*/


    this.state = {
      'destinations' : [],
      'isLoading'   : false,
      'error'       : false,
      'openExitModal': false,
      'filters' : {
        'window': {
          'visible': false,
          'active_tab': 'search'
        },
        'origin': {
          'iata': {
            'TLV': true,
            'ETM': false
          },
        },
        'limit'       : 10,
        'months': [],
        'months_weekends': false,
        'from_airport'  : 'TLV',
        'dates'         : '',
        'min_stars'     : 3,
        'max_stars'     : 5,
        //'min_rating'    : 7,
        'min_nights'      : 3,
        'max_nights'      : 7,
        'when'          : '',
        'when_caption'  : 'בתאריכים הכי זולים',
        'from'          : '',
        'to'            : '',
        'destination' : null,
        'destination_name': 'כל היעדים',
        'sortby'      : 'price-asc',
         //deals
      },
      'deals'       : deals,
      cities: [],  
    }


    // binding
    this.getFilters = this.getFilters.bind(this); 

    
    //https://stackoverflow.com/questions/54245395/how-can-i-scroll-to-a-div-onclick-using-react
    this.myDivToFocus = React.createRef()


  }

  componentDidMount() {
    //initGA('G-FJNX1JCZ7J');
    //PageView();
    document.title = 'דילים עד 199$';

    // Dynamic stylesheet Rule - Add to stylesheet new rules to control which image is shown on the billboard based on the user's platform (mobile / desktop)
    document.styleSheets[0].insertRule("@media only screen and (max-width : 500px) { .promotionBillboard { background-image:url('/images/promotions/max199/mobile-billboard.jpg'); } }",0);
    document.styleSheets[0].insertRule('.promotionBillboard { background-image:url(\'/images/promotions/max199/desktop-billboard.jpg\')}',0);


    //this.setState({'isLoading': true, filters: {...this.state.filters }});
    //this.refetchData()
  }

  refetchData() {
    
    this.query_options = new Map();
    this.query_options.set('min_stars',this.state.filters.min_stars);
    this.query_options.set('max_stars',this.state.filters.max_stars);
    this.query_options.set('from_airport',this.state.filters.from_airport);
    this.query_options.set('min_nights',this.state.filters.min_nights);
    this.query_options.set('max_nights',this.state.filters.max_nights);
    this.query_options.set('destination',this.state.filters.destination);
    this.query_options.set('dates',this.state.filters.dates);
    this.query_options.set('months',this.state.filters.months);
    this.query_options.set('months_weekends',this.state.filters.months_weekends);
    this.query_options.set('when',this.state.filters.when);
    this.query_options.set('sortby',this.state.filters.sortby);
    this.query_options.set('deals',this.state.deals);

    let querystring = "";
    this.query_options.forEach((value,key) => querystring+=`&${key}=${value}`) ;


    return (
      fetch(API_ENDPOINT + API_OBJECT + API_QUERY + querystring + API_PREDEFINED_QUERY)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Sorry, we couldn\'t get response from the remote server, please try again in few seconds (Error 303)');
        }
      })
      .then(data => {
        let cities = [];

        
        // Initialize the destinations array only on the first load (so it won't reload after filters were invoked and we'll lose some cities)
        if (this.state.cities.length === 0) 
          data.destinations.map(dest => cities.push(dest.destination))
        else
          cities = this.state.cities;
       
        this.setState({
            'destinations': this.state.filters?.sortby==='price-desc'?
              data.destinations.sort((b,a) => a.deals[0]?.price-b.deals[0]?.price):
              data.destinations.sort((a,b) => a.deals[0]?.price-b.deals[0]?.price), 
            'isLoading': false, 
            filters: {...this.state.filters },
            'cities':  cities
        })


      } )
      .catch(error => this.setState({
            error, 
            'isLoading': false
          }
      )));  
  }

  
  getFilters(filtersVal, clickedObject) {
    console.log(this.state.filters)

    if  (filtersVal.destination!==null && filtersVal.destination!=='null' && filtersVal.destination!==null && filtersVal.destination!=='' && filtersVal.destination!==null && filtersVal.destination!=='all') {
      const queryString = Object.keys(filtersVal).map(key => key + '=' + filtersVal[key]).join('&');
      
      window.location = '/' + filtersVal.destination_name  + '?' + queryString
      console.log(filtersVal)
      return
    }
    
    //.current is verification that your element has rendered
    if(this.myDivToFocus.current){
      this.myDivToFocus.current.scrollIntoView({ 
         behavior: "smooth", 
         block: "nearest"
      })
    }

    this.setState(
      {
        'destinations': [] ,
        'isLoading': true, 
        'filters': filtersVal,
        'openExitModal': this.state.openExitModal
      },
      //this.refetchData
    );

    
    // AUTO COLOR THE MOST RECENTLY CLICKED ELEMENT
    /*if (clickedObject!=null)
      setTimeout(function() {clickedObject.style.backgroundColor = '#cf576f'}, 1500);*/
  }

  onOpenModal = (e) => {
    //this.setState((prevState) => ({ 'open': true, 'active': prevState.active}));
    this.setState({openExitModal: true})
  };

  onCloseModal = () => {
    //this.setState((prevState) => ({ 'open': false, 'active': prevState.active}));
    //this.setState(...this.state,{openExitModal: false})
    this.setState({openExitModal: false})
  };

  onConfirmModal = () => {
    this.setState({openExitModal: false})
    //window.location = '/'
    window.location = '/?&min_stars=4&max_stars=5&from_airport=TLV&min_nights=3&max_nights=7&destination=null&dates=&months=&months_weekends=false&when=all&sortby=price-asc&from=&to=&intro=true'
  };

  
  render() {

    // conditional rendreing ahead
    const {error} = this.state;

    if (error) {
      return <p>{error.message}</p>;
    }

    //this.getFilters(this.state.filters,null)

    /*
    >>> MOVED TO THE TOP OF THIS PAGE!!! (inside the ComponentDidMount function)

      const style = {
        promotionBillboard: {
          backgroundImage:'url(/images/promotions/max199/desktop-billboard.jpg)'
        },
        '@media (max-width: 500px)': {
          promotionBillboard: {
            backgroundImage:'url(/images/promotions/max199/mobile-billboard.jpg)'
          }
        }
      };
      */

      return (
      <div className="App">
        <Modal open={this.state.openExitModal} onClose={this.onCloseModal} center rtl style={{direction:`rtl`}}>
          <div dir="rtl"  className="modal_content">
            <p><h4>שימו לב</h4></p>
            <p>רגע לפני שאתם עוזבים את דף הדילים של MAX...</p>
            <p>חשוב שתדעו שההנחה של 5% תקפה אך ורק על הדילים המוצגים בדף זה.</p>
            <p>מספר המקומות בכל דיל מוגבל, לכן מומלץ לא לחכות יותר מדי כדי שמישהו אחר לא יקדים אתכם... כל הקודם זוכה!</p>
            <br />
            <button onClick={this.onConfirmModal} className="btn promotions exit">הבנתי</button>
          </div>
        </Modal>
        <Header activeMenu='promotions' hide='true'  />
        <main>
            <div className="promotionBillboard" />

            <div className={'preloader ' + (this.state.isLoading?' show-item':' hide-item')}>
              <img src="/images/preloader.gif" alt="אנא המתן"/>
            </div>

            <div  ref={this.myDivToFocus}>
              <Deck data={this.state.destinations} type="deals" key="2" min_nights={this.state?.filters?.min_nights} max_nights={this.state?.filters?.max_nights} min_stars={this.state?.filters?.min_stars} max_stars={this.state?.filters?.max_stars}  promotion='max199' expired={true} deals={this.state?.deals} />
            </div>
            <button className="btn promotions exit" style={{marginTop:'1em'}} onClick={e=>this.onOpenModal(e)}>לדילים נוספים</button>
            <br />
            <br />
            <br />

            <br />


          </main>
          <Footer />
        </div>
      );
    
  }
}

export default Flights;
